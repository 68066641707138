/* ============================================================================= */
/* About section. */
/* ============================================================================= */

#dt-about {
  margin-top: -100px;
  position:   relative;
  @include media-breakpoint-down(xl) {margin-top:-75px;}
  @include media-breakpoint-down(lg) {margin-top:0px;}
  .dt-section-animated-side {
    position:   absolute;
    top:        0;
    bottom:     0;
    background: $body-bg;
    width:      1px;
  }

  .dt-section-animated-side-left {
    right:            100%;
    transform-origin: right center;
  }

  .dt-section-animated-side-right {
    left:             100%;
    transform-origin: left center;
  }
}
