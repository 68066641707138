.dt-testimonials-block {

    .dt-testimonials {
      background: rgba(51,51,51,0.5);
      padding: 60px;
      @include media-breakpoint-down(xl) {padding: 44px;}
      span {
        line-height: 1;
          i {
            font-size: 20px;
            opacity: 1;
            color:#fff;
          }
        }
        p {
          position: relative;
          font-weight: 400;
          line-height: 1.25;
          font-size: 21px;
          color:#fff;
          margin-bottom: 40px;
          margin-left: 25px;
          text-align: left !important;
          min-height: 156px;
          &:before {
            position: absolute;
            content: "“";
            font-family: Mukta;
            font-size: 30px;
            font-weight: bold;
            line-height: 1;
            top: 5px;
            left: -25px;
          }
        }
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
          display: inline-block;
          float: left;
          margin-right: 15px;
        }
        h6 {
          font-family: $font-family-base;
          font-size: 19px;
          line-height: 1.3;
          color: #fff;
          margin-bottom: 0;
          font-weight: 400;
          text-align: left !important;
          span {
            font-size: 16px;
            line-height: 1.5;
            color: #757575;
            font-weight: 400;
          }
        }
      }
  }

