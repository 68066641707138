/* ============================================================================= */
/* Fullscreen mobile navigation. */
/* ============================================================================= */
#dt-mobile-nav-container {
  @include overlay-base(fixed);
  z-index         : $zindex-fixed;
  background      : rgba(#000, 0.85);
  transition      : opacity 0.3s, visibility 0.3s;
  display         : flex;
  align-items     : center;
  justify-content : center;
  text-align      : center;
  opacity         : 0;
  visibility      : hidden;
}

/* ================================= fn menu toggle */
#dt-primary-nav #dt-mobile-nav-trigger {
  display        : inline-block;
  vertical-align : middle;
  position       : relative;
  font-size      : 18px;
  z-index        : $zindex-fixed + 10;
  margin         : 15px 0;
  width          : 1.5em;
  height         : 1em;
  color          : inherit;
  transition     : none;

  > span {
    display    : block;
    position   : absolute;
    height     : 2px;
    width      : 100%;
    background : currentColor;
    opacity    : 1;
    left       : 0;
    transform  : rotate(0deg);
    transition : all .3s ease-in-out, color 0.25s;

    &:nth-child(1) { top: 0; }
    &:nth-child(2), &:nth-child(3) { top: 50%; margin-top: -1px; }
    &:nth-child(4) { bottom: 0; }
  }

  .dt-active-burger-menu & > span {
    &:nth-child(1) { top: 50%; width: 0; left: 50%; }
    &:nth-child(2) { transform: rotate(45deg); }
    &:nth-child(3) { transform: rotate(-45deg); }
    &:nth-child(4) { bottom: 50%; width: 0; left: 50%; }
  }

  //&:hover { opacity: 0.6; }
}

/* ================================= social icons */
#dt-mobile-nav-container .dt-soc-icons {
  margin-top : 15px;
  font-size  : 0;
  opacity    : 0;

  > a {
    display   : inline-block;
    color     : $brand-primary;
    padding   : 0 15px;
    opacity   : 1;
    font-size : 23px;

    &:hover { color : #fff; }
  }
}

/* ================================= inner nav menu */
#dt-mobile-nav-container .nav_menu {
  @include reset-box-model(true);

  li > a.sub-active { color : $brand-primary; }

  > li.menu-item-has-children > a:after {
    content     : '\f3d0';
    font-family : $icon-font-family;
    margin-left : 5px;
    font-size   : 12px;
    position    : absolute;
    top         : 2px;
  }

  > li {
    position : relative;
    opacity  : 0;

    > a {
      font-size      : 20px;
      line-height    : 35px;
      text-transform : uppercase;
      padding-bottom : 15px;
      display        : block;
      color          : #fff;
      font-weight    : 400;

      &:hover { color : $brand-primary; }
    }
  }

  .sub-menu {
    @include reset-box-model(true);
    padding : 0 0 15px;
    display : none;

    a {
      font-size      : 16px;
      font-weight    : 400;
      text-align     : center;
      padding-bottom : 10px;
      color          : #fff;
      text-transform : none;
      display        : block;

      &:hover { color : $brand-primary; }

    }
  }
}

/* ================================= active state */
body.dt-active-burger-menu {

  #dt-mobile-nav-container {
    opacity    : 1;
    visibility : visible;

    .nav_menu > li, .dt-soc-icons {
      animation : dt-menu-effect 0.4s linear forwards;
    }
  }
  #dt-header {  
    &.header-stuck {
      .brand-logo {
        .logo-dark {display: none;}
        .logo-white {display: block;}
      }
    }
  }
}

@keyframes dt-menu-effect {
  from {
    opacity    : 0;
    visibility : hidden;
    transform  : translateY(15px);
  }
  to {
    opacity    : 1;
    visibility : visible;
    transform  : translateY(0);
  }
}