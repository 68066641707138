.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  visibility: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1060;
  display: none;
  overflow: hidden;
  outline: 0;
  background: #000;
  justify-content: center;
  align-items: center;
  .modal-open & {
    visibility: visible;
    overflow-x: hidden;
    overflow-y: auto;
    background:rgba(0,0,0,0.9);
  }
}


.modal-dialog {
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  pointer-events: none;
  background-color: #fff;
  border-radius: 10px;

  .modal.fade & {
    @include transition($modal-transition);
    transform: translate(0, -5%);
  }
  .modal.show & {
    transform: translate(0, 0);
  }

}


.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; 
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  padding: 32px 50px;
  .close {
    padding: 0;
    margin: 0;
    position: absolute;
    right: 20px;
    top: 10px;
    &:focus {
      outline:none;
    }
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  background-color: $modal-backdrop-bg;

  // Fade for backdrop
  &.fade { opacity: 0; }
  &.show { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  align-items: flex-start; // so the close btn always stays on the upper right corner
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  h4 {font-size:21px;}
  margin-bottom:30px;
}
.modal-title {
  margin-bottom: 0;
  line-height: $modal-title-line-height;

}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  .send-us-mes.audit {
    margin-top: 0px;
    .dt-contact-request {
      margin-top: 2rem;
      .btn {
        padding: 10px 20px;
        font-size: 14px;
      }
    }
    .returnmessage {
      p {
        position   : relative;
        top        : 0;
        left       : 0; 
        width      : 100%;
        animation: fadeInFromNone 0.75s ease-out;
        transform: translate(0, 15px);
        margin-bottom: 0;
      }
    }
  }
}

// Footer (for actions)
.modal-footer {
  display: flex;
  align-items: center; // vertically center
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: $modal-inner-padding;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;

  // Easily place margin between footer elements
  > :not(:first-child) { margin-left: .25rem; }
  > :not(:last-child) { margin-right: .25rem; }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: 400px;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));
  }

  .modal-content {
    @include box-shadow($modal-content-box-shadow-sm-up);
  }

  .modal-sm { max-width: $modal-sm; }

}

@include media-breakpoint-up(lg) {
  .modal-lg { max-width: $modal-lg; }
}
