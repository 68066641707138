/* ============================================================================= */
/* Services block. */
/* ============================================================================= */

.dt-services-container {
  @include clearfix;
  margin : 0 -20px 0px;
  @include media-breakpoint-down(lg) {margin : 0 -15px 0px;}
  width     : auto;
  max-width : none;

  > .dt-services-wrap {
    float   : left;
    width   : (100% / 3);
    padding : 0 20px 0px;
    @include media-breakpoint-down(lg) {padding : 0 15px 0px;}
    span {
      display: inline-block;
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: 1px;
      color: #757575;
      padding-left: 60px;
      @include media-breakpoint-down(lg) {padding-left: 40px;}
      margin-bottom: 15px;
      @include media-breakpoint-down(md) {display: none;}
    }
  }

  @include media-breakpoint-down(xl) {
    > .dt-services-wrap { width : 33.333333%; }
  }
  @include media-breakpoint-down(md) {
    > .dt-services-wrap { 
      width : 50%; 
      margin-bottom: 0px;
      &:last-child {
        margin-bottom:0px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    > .dt-services-wrap { width : 100%; margin-bottom: 0;}
  }
  @include media-breakpoint-down(sm) {
    margin-bottom : -30px;

    > .dt-services-wrap {
      width          : 100%;
      padding-bottom : 30px;
      @include media-breakpoint-down(md) {
        &:last-child {padding-bottom:0;}
      }
    }
  }
}

.dt-services-block {
    background:rgba(51,51,51,0.5);
    padding: 80px 60px;
    @include media-breakpoint-down(xl) {padding: 60px;}
    @include media-breakpoint-down(lg) {padding: 40px;}
    position: relative;
    margin-bottom:60px;
    @include media-breakpoint-down(md) {margin-bottom:30px;}
    .icon {
        display     : block;
        margin-bottom: 1rem;
        line-height : 1;
        font-size   : 56px;
        position: relative;
        z-index: 2;
    }
    .title {
      display       : block;
      line-height   : 1;
      h3 {
        font-size     : 25px;
        @include media-breakpoint-down(lg) {font-size: 24px;}
        line-height   : 1.5;
        margin-bottom : 0;
        color:#fff;
      }
    }
    p {
      margin-top: 15px;
      font-size: 17px;
      line-height: 1.56;
      color:#fff;
      margin-bottom: 0;
      height: 130px;
      @include media-breakpoint-down(md) {height: 182px; }
      @include media-breakpoint-down(sm) {height: auto; }
    }
    .list {
      margin-top: 10px;
      display: none;
      ul {
        padding: 0;
        margin: 0;
        li {
          position: relative;
          list-style: none;
          padding: 5px 0;
          color:#fff;
          line-height: 1.56;
          font-size:19px;
          @include media-breakpoint-down(lg) {font-size: 17px;}
          letter-spacing: normal;
          padding-left: 15px;
          &:last-child {padding-bottom: 0;}
          &:before {
            width: 6px;
            height: 6px;
            content: '';
            background: #fff;
            border-radius: 50%;
            position: absolute;
            left:0;
            top:17px;
            @include media-breakpoint-down(md) {display: none;}
          }
        }
      }
    }
  p:last-child { margin-bottom : 0; }
}