/* ============================================================================= */
/* Short features list. */
/* ============================================================================= */

.dt-short-features-list {
  @include reset-box-model(true);
  //text-align    : left;
  columns       : 3;
  column-gap    : 40px;
  margin-bottom : -0.5rem;

  > li {
    padding-left : 28px;
    position     : relative;
    display      : block;
    margin       : 0 auto 0.5rem;
  }

  > li:before {
    position    : absolute;
    top         : 0; left : 0;
    font-family : $icon-font-family;
    content     : '\e804';
    color       : $brand-primary;
    display     : inline-block;
  }


  @include media-breakpoint-down(md) { column-count : 2; }

  @include media-breakpoint-down(sm) {
    column-count : 1;

    > li { padding-left: 0; }
    > li:before { position: static; margin-right: 7px; }
  }
}