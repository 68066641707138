/* ============================================================================= */
/* Site header commons. */
/* ============================================================================= */
#dt-header {
  position      : absolute;
  top           : 0; left : 0;
  right         : 0;
  width         : 100%;
  z-index       : $zindex-fixed + 20;
  background    : transparent;
  padding       : 30px 0;
  color:#fff;
  border-bottom : 1px solid transparent;
  @include media-breakpoint-down(md) {margin:0;padding: 15px 0;}

}

#dt-header-inner {
  display     : flex;
  align-items : center;
}


/* ============================================================================= */
/* Header elements (logo, extras, etc) */
/* ============================================================================= */
#dt-header-inner > .brand-logo {
  user-select  : none;
  color        : inherit;
  font-size    : 2.1rem;
  margin-right : auto;
  min-width    : 0;
  line-height  : 1; 

  > h1 {
    @include reset-box-model();
    font-size : inherit;
    color     : inherit;
  }

  > h1 > span { color: $brand-primary; }

  img {
    max-width : 40%;
    height    : auto;
    &.sticky {display: none;}
  }
}

#dt-header.headroom--pinned #dt-header-inner > .brand-logo {
  .sticky {display: block}
  .regular {display: none}
}
#dt-header.headroom--top #dt-header-inner > .brand-logo {
  .regular {display: block;}
  .sticky {display: none}
}


/* ============================================================================= */
/* Header desktop navigation. */
/* ============================================================================= */
#dt-primary-nav {
  text-align  : right;
  line-height : 1;
}

#dt-primary-nav > .nav_menu {
  @include reset-box-model(true);
  @include clearfix;
  margin    : 0 -17px;
  font-size : 0;

  // root-level menu items
  > li {
    position  : relative;
    display   : inline-block;
    margin: 15px 20px 15px 20px;
    > a {
      display             : inline-block;
      font-size           : 16px;
      white-space         : nowrap;
      font-weight         : 600;
      color               : inherit;
      transition-property : opacity, color, background;
      text-transform: uppercase;
      position: relative;
      &:after {
        content: "";
        width: 0;
        height: 2px;
        background-color: #fff;
        position: absolute;
        left: auto;
        right: 0;
        bottom: -10px;
        transform: translateY(-50%);
        transition: all 0.35s ease-in;
      }
      &:hover:after {
        width: 100%;
        right: auto;
        left: 0;
      }
    }

    &.menu-contacts {
      button {
        display: inline-block;
        font-size: 16px;
        white-space: nowrap;
        font-weight: 600;
        color: inherit;
        transition-property: opacity, color, background;
        text-transform: uppercase;
        position: relative;
        padding: 15px 25px 12px 25px;
        transition: all 0.2s ease-in-out;
        background: #fff;
        border-radius: 26px;
        color:#2a2a2a;
        border:none;
        &:hover {
          background: rgba(255, 255, 255, 0.8);
          transition: all 0.2s ease-in-out;
          cursor: pointer;
        }
        &:focus {outline: none;}
      }
    }
  }

  // drop-downs
  .sub-menu {
    @include reset-box-model(true);
    transform  : translate3d(0, -10px, 0);
    transition : transform 0.35s, opacity 0.25s, visibility 0.35s;
    opacity    : 0;
    visibility : hidden;
    position   : absolute;
    top        : 100%; left : 0;
    box-shadow : 0 6px 12px rgba(0, 0, 0, 0.175);
    background : #fff;
    text-align : left;

    &.invert-attach-point {
      left  : auto;
      right : 0;
    }

    > li {
      display  : inline-block;
      position : relative;

      > a {
        position            : relative;
        display             : inline-block;
        padding             : 10px 20px;
        font-size           : 15px;
        color               : #333;
        width               : 220px;
        line-height         : 1.75;
        white-space         : normal;
        transition-property : background;
        text-transform      : capitalize;
        border-top          : 1px solid #eee;
      }

      &.menu-item-has-children > a:after {
        right        : 20px;
        position     : absolute;
        padding-left : 7px;
        content      : '\e81f';
        font-size    : 14px;
        font-family  : $icon-font-family;
      }

      &:hover > a { background : #f5f5f5; }
    }

    // deep-level drop-downs
    .sub-menu {
      transform : translate3d(-10px, 0, 0);
      left      : 100%;
      top       : 0;

      &.invert-attach-point {
        transform : translate3d(10px, 0, 0);
        right     : 100%;
        left      : auto;
      }
    }
  }

  // multi-column modes
  > li.dt-mega-menu > ul {
    border-top : 1px solid #eee;

    > li > a {
      width         : 200px;
      border-top    : 0;
      border-bottom : 1px solid #eee;
      border-right  : 1px solid #eee;
    }
  }

  // hover effect
  li.menu-item-has-children:hover > .sub-menu {
    transform  : translate3d(0, 0, 0);
    opacity    : 1;
    visibility : visible;
  }
}