/* ============================================================================= */
/* Flipping button. */
/* ============================================================================= */

.dt-flip-btn {
  display       : inline-block;
  position      : relative;
  text-align    : center;
  width         : 175px;
  padding       : 15px 17px;
  transition    : border-radius 0.25s;
  background    : $brand-primary;
  color         : #fff !important;
  border-radius : $btn-border-radius;

  > .back, > .front {
    display    : inline-block;
    transform  : translate3d(0, 0, 0);
    transition : all 0.25s;
  }

  > .front {
    transition-delay : 0.25s;
  }

  > .back {
    opacity    : 0;
    display    : inline-block;
    position   : absolute;
    top        : 50%; left : 0;
    width      : 100%;
    transform  : translateY(calc(-50% - 15px));
    text-align : center;
    font-size: 25px;
    i:before {
      background-image: linear-gradient(135deg, #21D4FD 0%, #4cafe1 75%);
      color: transparent !important;
      -webkit-background-clip: text;
      background-clip: text;
    }
  }

  i { margin-left : 5px; }

  &:hover {
    //box-shadow: none;
    cursor: pointer;
    > .front { opacity : 0; transform : translateY(15px); transition-delay : 0s; }
    > .back { opacity : 1; transform : translateY(-50%); transition-delay : 0.25s; background: #fff;border-radius: 30px;}
  }
}