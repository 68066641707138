/* Contacts */

.dt-contacts-list {
  ul {padding: 0;margin: 0;
    li {list-style: none;margin-bottom: 0.45rem;
      i {font-size: 30px;padding-right: 8px;position: relative; top:5px;}
    }
  }
}
.dt-contact-caption {
    font-size: 18px;
    margin-bottom: 18px;
    color:#2a2a2a;
    line-height: 1.56;
    font-weight: 600;
    margin-top: 60px;
    @include media-breakpoint-down(md) {text-align: center;}
}
.dt-contacts-info {
  color: #757575;
  line-height: 1.56;
  font-size: 18px;
  p {margin-bottom: 10px;}
  a {
    color:#2a2a2a;
    line-height: 1.5;
    margin-left: -19px;
    font-size:22px;
    display: inline-block;
    margin-bottom: 15px;
  }
  i {
    left: -30px;
    position: relative;
    font-size: 24px;
    top:2px;
  }
  .dt-mail {
    margin-top: 15px;
    i {font-size: 22px;top:0;}
  }
}
.dt-contacts-social {
  margin-top: 20px;
  display: inline-block;
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      display: inline-block;
      padding-right: 15px;
      a {
        color:#333;
        background: #fff;
        padding: 5px 7px;
        line-height: 1;
        border-radius: 4px;
        i {font-size: 16px;}
      }
    }
  }
}
.send-us-mes {
  position: relative;
  margin-top: 60px;
  text-align: left !important;
  .form-group {
    position: relative;
    margin: 0 0 30px 0;
  }  
  .dt-contact-caption {
    margin-top: 0;
  }
  .dt-contact-form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {display: block;}
  }
.dt-contact-checkbox-wrap {
  margin-bottom: 0;
  @include media-breakpoint-down(sm) {margin-bottom: 15px; width: 50%; float: left; &.last {margin-bottom: 0;}}
  &.last {margin-bottom: 0;}
  .dt-contacts-checkbox {
    margin: auto; 
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    span {
      display: inline-block;
      vertical-align: middle;
      transform: translate3d(0,0,0);
      color:#757575;
      line-height: 1.56;
      font-size: 18px;
      &:first-child {
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 2px;
        transform: scale(1);
        vertical-align: middle;
        border: 1px solid #d8d8d8;
        transition: all .2s ease;
        svg {
          position: absolute;
          top: 4px;
          left: 3px;
          fill: none;
          stroke: #FFFFFF;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 20px;
          stroke-dashoffset: 20px;
          transition: all .3s ease;
          transition-delay: .1s;
          transform: translate3d(0,0,0);
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          background: #2a2a2a;
          display: block;
          transform: scale(0);
          opacity: 1;
          border-radius: 50%;
        }
      }
    }
    &:last-child {
      padding-left: 8px;
      }
    }
    &:hover span:first-child {
      border-color: #2a2a2a;
    }
  }
  .dt-contacts-checkbox-check:checked + .dt-contacts-checkbox {
    span {
      &:first-child {
        background: #2a2a2a;
        border-color: #2a2a2a;
        svg {
          stroke-dashoffset: 0;
        }
      }
    }
  }
}

  
  ::placeholder { color: #999; } 
  .custom-select {
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #eee;
    color:#999;
    padding: 0;
    height: 51px;
  }

  textarea {
    height: 40px;
    min-height: 38px;
    letter-spacing: 0.04em;
    line-height: 1.56;
    border:none;
    border-bottom: 2px solid #eee;
    color:#2a2a2a;
    font-size: 18px;
  }
  .dt-attach-file {
    margin:10px 30px 0 30px;
    display: inline-block;
    @include media-breakpoint-down(md) {width: 100%; text-align: center;margin: 0 0 -30px 0;}
    label {
      color:#2a2a2a;
      line-height: 1.5;
      border-bottom: 2px solid #2a2a2a;
      font-size: 18px;
      position: relative;
      &:before {
        position:absolute;
        content: '+';
        font-size: 30px;
        left:-30px;
        top:-6px;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .dt-file-input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }
  }
  .dt-contact-request {
    margin-top: 30px;
    @include media-breakpoint-down(md) {text-align: center;}
    .btn {
      background: #2A2A2A;
      padding: 14px 33px;
    }
  }
  .returnmessage {
    position: relative;
    display: inline-block;
    p {
      //animation: fadeInFromNone 0.75s ease-out;
      //transform: translate(0, 15px);
      margin-bottom: 0;
    }
  }
}

@keyframes fadeInFromNone {
    0% {
        opacity: 0;
        transform: translate(0, 0);
    }

    100% {
        opacity: 1;
        transform: translate(0, 15px);
    }
}




.dt-contacts-effect {
  padding: 4px 0;
  border-bottom: 2px solid #eee;
  background-color: transparent;

  ~ .focus-border{
    position: absolute; 
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #2a2a2a;
    transition: 0.3s;
    }

    &:focus ~ .focus-border, &.has-content ~ .focus-border{
      width: 100%;
      transition: 0.3s;
    }
    
    &:focus {
      background: transparent;
    }

    &:focus ~ label, &.has-content ~ label {
      top: -16px; 
      font-size: 14px;
      color: #2A2A2A;
      transition: 0.3s;
    }

    ~ label {
      position: absolute;
      left: 0;
      width: 100%;
      top: 7px;
      color: #aaa;
      transition: 0.3s;
      z-index: -1;
      letter-spacing: 1px;
      margin: 0;
    }
  }