/* ============================================================================= */
/* Primary site preloader. */
/* ============================================================================= */

#dt-preloader {
  &:after, &:before {
    @include overlay-base(fixed);
    z-index    : 15001;
    background : #2a2a2a;
    transform-origin: top center;
    content: '';
  }

  &:before {
    content: 'loading...';
    font-weight: 400;
    font-size: 2rem;
    font-family: Arial, Geneva, Verdana, sans-serif;
    letter-spacing: 3px;
    -webkit-text-stroke: 2px #fff;
    text-stroke: 2px #fff;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:after {
      z-index: 15000;
      background: #fff;
  }

  .dt-page-loaded &:after, .dt-page-loaded &:before {
    animation: dt-preloader-slideup 0.5s 0.5s forwards ease;
  }

  .dt-page-loaded &:after {
    animation-delay: 1s;
  }
}

@keyframes dt-preloader-slideup {
    0% {
        transform: scaleY(1);
        -webkit-text-stroke-color: #fff;
        text-stroke-color: #fff;
    }

    80% {
        -webkit-text-stroke-color: transparent;
        text-stroke-color: transparent;
    }

    100% {
        transform: scaleY(0);
    }
}