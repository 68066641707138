/* ============================================================================= */
/* pricing block. */
/* ============================================================================= */

.dt-pricing-container {
  @include clearfix;
  margin : 0 -20px 0px;
  @include media-breakpoint-down(lg) {margin : 0 -15px 0px;}
  width     : auto;
  max-width : none;

  > .dt-pricing-wrap {
    float   : left;
    width   : (100% / 3);
    padding : 0 20px 0px;
    @include media-breakpoint-down(lg) {padding : 0 15px 0px;}
  }

  @include media-breakpoint-down(xl) {
    > .dt-pricing-wrap { width : 33.333333%; }
  }
  @include media-breakpoint-down(md) {
    > .dt-pricing-wrap { 
      width : 100%; 
      margin-bottom: 30px;
      &:last-child {
        margin-bottom:0px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    > .dt-pricing-wrap { width : 100%; margin-bottom: 0;}
  }
  @include media-breakpoint-down(sm) {
    margin-bottom : -30px;

    > .dt-pricing-wrap {
      width          : 100%;
      padding-bottom : 30px;
      @include media-breakpoint-down(md) {
        &:last-child {padding-bottom:0;}
      }
    }
  }
}

.dt-pricing-block {
    background:#fff;
    //padding: 80px 60px;
    //@include media-breakpoint-down(xl) {padding: 60px;}
    //@include media-breakpoint-down(lg) {padding: 40px;}
    position: relative;
    background: rgba(51,51,51,0.5);
    &:hover {
      .list ul:after {
        transition: opacity 0.3s linear;
        opacity:1;
      }
    }
    .title {
      display       : block;
      line-height   : 1;
      padding: 30px 60px 30px 60px;
      background:#fff;
      h3 {
        font-size     : 30px;
        @include media-breakpoint-down(lg) {font-size: 24px;}
        line-height   : 1.5;
        margin-bottom : 10px;
      }
      p {
        color:#777;
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 0;
      }
    }
    .price-wrap {
      padding: 30px 60px 30px 60px;
      
      .price {
        width: 100%;
        h1{
          margin:0;
          font-size: 52px;
          @include media-breakpoint-down(sm) {font-size: 40px;margin-right: 10px;}
          color:#fff;
          display: inline-block;
          margin-right: 15px;
        }
        span {
          width: 2px;
          height: 43px;
          @include media-breakpoint-down(sm) {height: 34px;margin-right: 10px;}
          background:#555;
          display: inline-block;
          margin-right: 15px;
        }
        p {
          display: inline-block;
          color:#fff;
          line-height: 1.3;
          font-size: 14px;
          margin-bottom: 1rem;
          del {
            font-size:26px;
            @include media-breakpoint-down(sm) {font-size: 22px;}
            padding-right: 10px;
            display: block;
          }
        }
      }
      a.btn.btn-white {
        margin-top: 10px;
        font-size: 17px;
        background: #fff;
        padding: 6px 18px 5px 18px;
        letter-spacing: 1px;
        transition: all 0.2s ease-in-out;
        &:hover {
        background: rgba(255, 255, 255, 0.8);
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        color:inherit;
        }
      }
    }
    .list {
      padding: 30px 60px;
      background:#fff;
      ul {
        position: relative;
        padding: 0;
        margin: 0;
        max-height: 240px;
        height: auto;
        transition: max-height 0.3s ease-in;
        overflow: hidden;
        &.height {
          max-height: 749px;
          transition: max-height 0.3s ease-in;
          &:after {content: '\e806';opacity: 0;cursor: default;}
        }
        &:after {
          font-family: "fontello";
          content: '\e805';
          position: absolute;
          bottom: 0;
          background: rgba(255,255,255,0.8);
          text-align: center;
          font-size:18px;
          color:#000;
          border-top: 1px solid #eee;
          line-height: 35px;
          width: 100%;
          opacity: 0;
          cursor: pointer;
          transition: opacity 0.3s ease-in;
        }
        li {
          position: relative;
          list-style: none;
          padding: 7px 0;
          line-height: 1.56;
          font-size:17px;
          border-bottom: 1px solid #eee;
          @include media-breakpoint-down(lg) {font-size: 17px;}
          &:last-child {padding-bottom: 0;border-bottom: none;}
        }
      }
    }
  p:last-child { margin-bottom : 0; }
}