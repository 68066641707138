.dt-portfolio-wrap {
  .dt-portfolio-block-data {
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }

  .dt-portfolio-block {
  position: relative;
  overflow: hidden;
  height: 450px;
  @media (min-width: 1920px) {height: 500px;}
  @include media-breakpoint-down(xl) {height: 400px;}
  @include media-breakpoint-down(xs) {height: 300px;}
    &:hover {
      cursor: grab;
    }
    &.andaman {.dt-portfolio-screen {background-image: url('../images/andaman.jpg');}}
    &.soprano {.dt-portfolio-screen {background-image: url('../images/soprano.jpg');}}
    &.unibody {.dt-portfolio-screen {background-image: url('../images/unibody.jpg');}}
    &.universal {.dt-portfolio-screen {background-image: url('../images/universal.jpg');}}
    &.insomnia {.dt-portfolio-screen {background-image: url('../images/insomnia.jpg');}}
    &.pioneer {.dt-portfolio-screen {background-image: url('../images/pioneer.jpg');}}
    &.avenda {.dt-portfolio-screen {background-image: url('../images/avenda.jpg');}}
    &.pheromone {.dt-portfolio-screen {background-image: url('../images/pheromone.jpg');}}
    
    &:after {
      content: '';
      background: rgba(0,0,0,0.75); 
      transition : all 0.6s;
      z-index: 20;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
      position: absolute;  
    }
    &:before {
      content: '';
      border:10px solid rgba(51,51,51,0.8);
      transition : all 0.6s;
      z-index: 21;
      width: 100%;
      height: 100%;
      top:0;
      left:0;
      position: absolute;  
    }
    a {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 25;
    }
    .dt-portfolio-screen {
      will-change: transform;
      position: absolute;
      z-index: 18;
      top: -20px;
      left: -20px;
      bottom: -20px;
      right: -20px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      filter: grayscale(100%); transition : all 0.6s; 
    }
  }
    .owl-item.active.center {
      .dt-portfolio-block:after {
        background: none;
        transition : all 0.6s; 
      }
      .dt-portfolio-block:before {
        border:10px solid rgba(255,255,255,0);
        transition : all 0.6s; 
      }
      .dt-portfolio-screen {
        filter: none;
        top: -50px;
        left: -50px;
        bottom: -50px;
        right: -50px;transition : all 0.6s; 
      }
    }
    @include media-breakpoint-down(xs) {
      .owl-item.active {
        .dt-portfolio-block:after {
          background: none;
          transition : all 0.6s; 
        }
        .dt-portfolio-block:before {
          border:none;
          transition : all 0.6s; 
        }
        .dt-portfolio-screen {filter: none;}
      }
    }
}
.dt-portfolio-hash {
  margin-top: 30px;
  span {
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 1px;
    color: #757575;
  }
  h2 {
    padding-top: 7px;
    color:#fff;
    font-size: 30px;
    margin-bottom: 20px;
  }
  p {
    font-size: 13px;
    line-height: 1.33;
    letter-spacing: 1px;
    color: #d0d0d0;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}
