@charset "UTF-8";
/* ============================================================================= */
/* Helper mixins */
/* ============================================================================= */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.hinge {
  animation-duration: 2s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    -ms-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    -ms-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    -ms-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    -ms-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    -ms-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    -ms-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg);
  }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    -ms-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg);
  }
  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    -ms-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }
  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  animation-name: hinge;
}

/*
    Flaticon icon font: Flaticon
    Creation date: 22/06/2016 15:41
    */
@font-face {
  font-family: "Flaticon";
  src: url("../icon-font/font/Flaticon.eot");
  src: url("../icon-font/font/Flaticon.eot?#iefix") format("embedded-opentype"), url("../icon-font/font/Flaticon.woff") format("woff"), url("../icon-font/font/Flaticon.ttf") format("truetype"), url("../icon-font/font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../icon-font/font/Flaticon.svg#Flaticon") format("svg");
  }
}

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.flaticon-add:before {
  content: "\f100";
}

.flaticon-add-1:before {
  content: "\f101";
}

.flaticon-add-2:before {
  content: "\f102";
}

.flaticon-add-3:before {
  content: "\f103";
}

.flaticon-agenda:before {
  content: "\f104";
}

.flaticon-alarm:before {
  content: "\f105";
}

.flaticon-alarm-1:before {
  content: "\f106";
}

.flaticon-alarm-clock:before {
  content: "\f107";
}

.flaticon-alarm-clock-1:before {
  content: "\f108";
}

.flaticon-albums:before {
  content: "\f109";
}

.flaticon-app:before {
  content: "\f10a";
}

.flaticon-archive:before {
  content: "\f10b";
}

.flaticon-archive-1:before {
  content: "\f10c";
}

.flaticon-archive-2:before {
  content: "\f10d";
}

.flaticon-archive-3:before {
  content: "\f10e";
}

.flaticon-attachment:before {
  content: "\f10f";
}

.flaticon-back:before {
  content: "\f110";
}

.flaticon-battery:before {
  content: "\f111";
}

.flaticon-battery-1:before {
  content: "\f112";
}

.flaticon-battery-2:before {
  content: "\f113";
}

.flaticon-battery-3:before {
  content: "\f114";
}

.flaticon-battery-4:before {
  content: "\f115";
}

.flaticon-battery-5:before {
  content: "\f116";
}

.flaticon-battery-6:before {
  content: "\f117";
}

.flaticon-battery-7:before {
  content: "\f118";
}

.flaticon-battery-8:before {
  content: "\f119";
}

.flaticon-battery-9:before {
  content: "\f11a";
}

.flaticon-binoculars:before {
  content: "\f11b";
}

.flaticon-blueprint:before {
  content: "\f11c";
}

.flaticon-bluetooth:before {
  content: "\f11d";
}

.flaticon-bluetooth-1:before {
  content: "\f11e";
}

.flaticon-bookmark:before {
  content: "\f11f";
}

.flaticon-bookmark-1:before {
  content: "\f120";
}

.flaticon-briefcase:before {
  content: "\f121";
}

.flaticon-broken-link:before {
  content: "\f122";
}

.flaticon-calculator:before {
  content: "\f123";
}

.flaticon-calculator-1:before {
  content: "\f124";
}

.flaticon-calendar:before {
  content: "\f125";
}

.flaticon-calendar-1:before {
  content: "\f126";
}

.flaticon-calendar-2:before {
  content: "\f127";
}

.flaticon-calendar-3:before {
  content: "\f128";
}

.flaticon-calendar-4:before {
  content: "\f129";
}

.flaticon-calendar-5:before {
  content: "\f12a";
}

.flaticon-calendar-6:before {
  content: "\f12b";
}

.flaticon-calendar-7:before {
  content: "\f12c";
}

.flaticon-checked:before {
  content: "\f12d";
}

.flaticon-checked-1:before {
  content: "\f12e";
}

.flaticon-clock:before {
  content: "\f12f";
}

.flaticon-clock-1:before {
  content: "\f130";
}

.flaticon-close:before {
  content: "\f131";
}

.flaticon-cloud:before {
  content: "\f132";
}

.flaticon-cloud-computing:before {
  content: "\f133";
}

.flaticon-cloud-computing-1:before {
  content: "\f134";
}

.flaticon-cloud-computing-2:before {
  content: "\f135";
}

.flaticon-cloud-computing-3:before {
  content: "\f136";
}

.flaticon-cloud-computing-4:before {
  content: "\f137";
}

.flaticon-cloud-computing-5:before {
  content: "\f138";
}

.flaticon-command:before {
  content: "\f139";
}

.flaticon-compact-disc:before {
  content: "\f13a";
}

.flaticon-compact-disc-1:before {
  content: "\f13b";
}

.flaticon-compact-disc-2:before {
  content: "\f13c";
}

.flaticon-compass:before {
  content: "\f13d";
}

.flaticon-compose:before {
  content: "\f13e";
}

.flaticon-controls:before {
  content: "\f13f";
}

.flaticon-controls-1:before {
  content: "\f140";
}

.flaticon-controls-2:before {
  content: "\f141";
}

.flaticon-controls-3:before {
  content: "\f142";
}

.flaticon-controls-4:before {
  content: "\f143";
}

.flaticon-controls-5:before {
  content: "\f144";
}

.flaticon-controls-6:before {
  content: "\f145";
}

.flaticon-controls-7:before {
  content: "\f146";
}

.flaticon-controls-8:before {
  content: "\f147";
}

.flaticon-controls-9:before {
  content: "\f148";
}

.flaticon-database:before {
  content: "\f149";
}

.flaticon-database-1:before {
  content: "\f14a";
}

.flaticon-database-2:before {
  content: "\f14b";
}

.flaticon-database-3:before {
  content: "\f14c";
}

.flaticon-diamond:before {
  content: "\f14d";
}

.flaticon-diploma:before {
  content: "\f14e";
}

.flaticon-dislike:before {
  content: "\f14f";
}

.flaticon-dislike-1:before {
  content: "\f150";
}

.flaticon-divide:before {
  content: "\f151";
}

.flaticon-divide-1:before {
  content: "\f152";
}

.flaticon-division:before {
  content: "\f153";
}

.flaticon-document:before {
  content: "\f154";
}

.flaticon-download:before {
  content: "\f155";
}

.flaticon-edit:before {
  content: "\f156";
}

.flaticon-edit-1:before {
  content: "\f157";
}

.flaticon-eject:before {
  content: "\f158";
}

.flaticon-eject-1:before {
  content: "\f159";
}

.flaticon-equal:before {
  content: "\f15a";
}

.flaticon-equal-1:before {
  content: "\f15b";
}

.flaticon-equal-2:before {
  content: "\f15c";
}

.flaticon-error:before {
  content: "\f15d";
}

.flaticon-exit:before {
  content: "\f15e";
}

.flaticon-exit-1:before {
  content: "\f15f";
}

.flaticon-exit-2:before {
  content: "\f160";
}

.flaticon-eyeglasses:before {
  content: "\f161";
}

.flaticon-fast-forward:before {
  content: "\f162";
}

.flaticon-fast-forward-1:before {
  content: "\f163";
}

.flaticon-fax:before {
  content: "\f164";
}

.flaticon-file:before {
  content: "\f165";
}

.flaticon-file-1:before {
  content: "\f166";
}

.flaticon-file-2:before {
  content: "\f167";
}

.flaticon-film:before {
  content: "\f168";
}

.flaticon-fingerprint:before {
  content: "\f169";
}

.flaticon-flag:before {
  content: "\f16a";
}

.flaticon-flag-1:before {
  content: "\f16b";
}

.flaticon-flag-2:before {
  content: "\f16c";
}

.flaticon-flag-3:before {
  content: "\f16d";
}

.flaticon-flag-4:before {
  content: "\f16e";
}

.flaticon-focus:before {
  content: "\f16f";
}

.flaticon-folder:before {
  content: "\f170";
}

.flaticon-folder-1:before {
  content: "\f171";
}

.flaticon-folder-10:before {
  content: "\f172";
}

.flaticon-folder-11:before {
  content: "\f173";
}

.flaticon-folder-12:before {
  content: "\f174";
}

.flaticon-folder-13:before {
  content: "\f175";
}

.flaticon-folder-14:before {
  content: "\f176";
}

.flaticon-folder-15:before {
  content: "\f177";
}

.flaticon-folder-16:before {
  content: "\f178";
}

.flaticon-folder-17:before {
  content: "\f179";
}

.flaticon-folder-18:before {
  content: "\f17a";
}

.flaticon-folder-19:before {
  content: "\f17b";
}

.flaticon-folder-2:before {
  content: "\f17c";
}

.flaticon-folder-3:before {
  content: "\f17d";
}

.flaticon-folder-4:before {
  content: "\f17e";
}

.flaticon-folder-5:before {
  content: "\f17f";
}

.flaticon-folder-6:before {
  content: "\f180";
}

.flaticon-folder-7:before {
  content: "\f181";
}

.flaticon-folder-8:before {
  content: "\f182";
}

.flaticon-folder-9:before {
  content: "\f183";
}

.flaticon-forbidden:before {
  content: "\f184";
}

.flaticon-funnel:before {
  content: "\f185";
}

.flaticon-garbage:before {
  content: "\f186";
}

.flaticon-garbage-1:before {
  content: "\f187";
}

.flaticon-garbage-2:before {
  content: "\f188";
}

.flaticon-gift:before {
  content: "\f189";
}

.flaticon-help:before {
  content: "\f18a";
}

.flaticon-hide:before {
  content: "\f18b";
}

.flaticon-hold:before {
  content: "\f18c";
}

.flaticon-home:before {
  content: "\f18d";
}

.flaticon-home-1:before {
  content: "\f18e";
}

.flaticon-home-2:before {
  content: "\f18f";
}

.flaticon-hourglass:before {
  content: "\f190";
}

.flaticon-hourglass-1:before {
  content: "\f191";
}

.flaticon-hourglass-2:before {
  content: "\f192";
}

.flaticon-hourglass-3:before {
  content: "\f193";
}

.flaticon-house:before {
  content: "\f194";
}

.flaticon-id-card:before {
  content: "\f195";
}

.flaticon-id-card-1:before {
  content: "\f196";
}

.flaticon-id-card-2:before {
  content: "\f197";
}

.flaticon-id-card-3:before {
  content: "\f198";
}

.flaticon-id-card-4:before {
  content: "\f199";
}

.flaticon-id-card-5:before {
  content: "\f19a";
}

.flaticon-idea:before {
  content: "\f19b";
}

.flaticon-incoming:before {
  content: "\f19c";
}

.flaticon-infinity:before {
  content: "\f19d";
}

.flaticon-info:before {
  content: "\f19e";
}

.flaticon-internet:before {
  content: "\f19f";
}

.flaticon-key:before {
  content: "\f1a0";
}

.flaticon-lamp:before {
  content: "\f1a1";
}

.flaticon-layers:before {
  content: "\f1a2";
}

.flaticon-layers-1:before {
  content: "\f1a3";
}

.flaticon-like:before {
  content: "\f1a4";
}

.flaticon-like-1:before {
  content: "\f1a5";
}

.flaticon-like-2:before {
  content: "\f1a6";
}

.flaticon-link:before {
  content: "\f1a7";
}

.flaticon-list:before {
  content: "\f1a8";
}

.flaticon-list-1:before {
  content: "\f1a9";
}

.flaticon-lock:before {
  content: "\f1aa";
}

.flaticon-lock-1:before {
  content: "\f1ab";
}

.flaticon-locked:before {
  content: "\f1ac";
}

.flaticon-locked-1:before {
  content: "\f1ad";
}

.flaticon-locked-2:before {
  content: "\f1ae";
}

.flaticon-locked-3:before {
  content: "\f1af";
}

.flaticon-locked-4:before {
  content: "\f1b0";
}

.flaticon-locked-5:before {
  content: "\f1b1";
}

.flaticon-locked-6:before {
  content: "\f1b2";
}

.flaticon-login:before {
  content: "\f1b3";
}

.flaticon-magic-wand:before {
  content: "\f1b4";
}

.flaticon-magnet:before {
  content: "\f1b5";
}

.flaticon-magnet-1:before {
  content: "\f1b6";
}

.flaticon-magnet-2:before {
  content: "\f1b7";
}

.flaticon-map:before {
  content: "\f1b8";
}

.flaticon-map-1:before {
  content: "\f1b9";
}

.flaticon-map-2:before {
  content: "\f1ba";
}

.flaticon-map-location:before {
  content: "\f1bb";
}

.flaticon-megaphone:before {
  content: "\f1bc";
}

.flaticon-megaphone-1:before {
  content: "\f1bd";
}

.flaticon-menu:before {
  content: "\f1be";
}

.flaticon-menu-1:before {
  content: "\f1bf";
}

.flaticon-menu-2:before {
  content: "\f1c0";
}

.flaticon-menu-3:before {
  content: "\f1c1";
}

.flaticon-menu-4:before {
  content: "\f1c2";
}

.flaticon-microphone:before {
  content: "\f1c3";
}

.flaticon-microphone-1:before {
  content: "\f1c4";
}

.flaticon-minus:before {
  content: "\f1c5";
}

.flaticon-minus-1:before {
  content: "\f1c6";
}

.flaticon-more:before {
  content: "\f1c7";
}

.flaticon-more-1:before {
  content: "\f1c8";
}

.flaticon-more-2:before {
  content: "\f1c9";
}

.flaticon-multiply:before {
  content: "\f1ca";
}

.flaticon-multiply-1:before {
  content: "\f1cb";
}

.flaticon-music-player:before {
  content: "\f1cc";
}

.flaticon-music-player-1:before {
  content: "\f1cd";
}

.flaticon-music-player-2:before {
  content: "\f1ce";
}

.flaticon-music-player-3:before {
  content: "\f1cf";
}

.flaticon-mute:before {
  content: "\f1d0";
}

.flaticon-muted:before {
  content: "\f1d1";
}

.flaticon-navigation:before {
  content: "\f1d2";
}

.flaticon-navigation-1:before {
  content: "\f1d3";
}

.flaticon-network:before {
  content: "\f1d4";
}

.flaticon-newspaper:before {
  content: "\f1d5";
}

.flaticon-next:before {
  content: "\f1d6";
}

.flaticon-note:before {
  content: "\f1d7";
}

.flaticon-notebook:before {
  content: "\f1d8";
}

.flaticon-notebook-1:before {
  content: "\f1d9";
}

.flaticon-notebook-2:before {
  content: "\f1da";
}

.flaticon-notebook-3:before {
  content: "\f1db";
}

.flaticon-notebook-4:before {
  content: "\f1dc";
}

.flaticon-notebook-5:before {
  content: "\f1dd";
}

.flaticon-notepad:before {
  content: "\f1de";
}

.flaticon-notepad-1:before {
  content: "\f1df";
}

.flaticon-notepad-2:before {
  content: "\f1e0";
}

.flaticon-notification:before {
  content: "\f1e1";
}

.flaticon-paper-plane:before {
  content: "\f1e2";
}

.flaticon-paper-plane-1:before {
  content: "\f1e3";
}

.flaticon-pause:before {
  content: "\f1e4";
}

.flaticon-pause-1:before {
  content: "\f1e5";
}

.flaticon-percent:before {
  content: "\f1e6";
}

.flaticon-percent-1:before {
  content: "\f1e7";
}

.flaticon-perspective:before {
  content: "\f1e8";
}

.flaticon-photo-camera:before {
  content: "\f1e9";
}

.flaticon-photo-camera-1:before {
  content: "\f1ea";
}

.flaticon-photos:before {
  content: "\f1eb";
}

.flaticon-picture:before {
  content: "\f1ec";
}

.flaticon-picture-1:before {
  content: "\f1ed";
}

.flaticon-picture-2:before {
  content: "\f1ee";
}

.flaticon-pin:before {
  content: "\f1ef";
}

.flaticon-placeholder:before {
  content: "\f1f0";
}

.flaticon-placeholder-1:before {
  content: "\f1f1";
}

.flaticon-placeholder-2:before {
  content: "\f1f2";
}

.flaticon-placeholder-3:before {
  content: "\f1f3";
}

.flaticon-placeholders:before {
  content: "\f1f4";
}

.flaticon-play-button:before {
  content: "\f1f5";
}

.flaticon-play-button-1:before {
  content: "\f1f6";
}

.flaticon-plus:before {
  content: "\f1f7";
}

.flaticon-power:before {
  content: "\f1f8";
}

.flaticon-previous:before {
  content: "\f1f9";
}

.flaticon-price-tag:before {
  content: "\f1fa";
}

.flaticon-print:before {
  content: "\f1fb";
}

.flaticon-push-pin:before {
  content: "\f1fc";
}

.flaticon-radar:before {
  content: "\f1fd";
}

.flaticon-reading:before {
  content: "\f1fe";
}

.flaticon-record:before {
  content: "\f1ff";
}

.flaticon-repeat:before {
  content: "\f200";
}

.flaticon-repeat-1:before {
  content: "\f201";
}

.flaticon-restart:before {
  content: "\f202";
}

.flaticon-resume:before {
  content: "\f203";
}

.flaticon-rewind:before {
  content: "\f204";
}

.flaticon-rewind-1:before {
  content: "\f205";
}

.flaticon-route:before {
  content: "\f206";
}

.flaticon-save:before {
  content: "\f207";
}

.flaticon-search:before {
  content: "\f208";
}

.flaticon-search-1:before {
  content: "\f209";
}

.flaticon-send:before {
  content: "\f20a";
}

.flaticon-server:before {
  content: "\f20b";
}

.flaticon-server-1:before {
  content: "\f20c";
}

.flaticon-server-2:before {
  content: "\f20d";
}

.flaticon-server-3:before {
  content: "\f20e";
}

.flaticon-settings:before {
  content: "\f20f";
}

.flaticon-settings-1:before {
  content: "\f210";
}

.flaticon-settings-2:before {
  content: "\f211";
}

.flaticon-settings-3:before {
  content: "\f212";
}

.flaticon-settings-4:before {
  content: "\f213";
}

.flaticon-settings-5:before {
  content: "\f214";
}

.flaticon-settings-6:before {
  content: "\f215";
}

.flaticon-settings-7:before {
  content: "\f216";
}

.flaticon-settings-8:before {
  content: "\f217";
}

.flaticon-settings-9:before {
  content: "\f218";
}

.flaticon-share:before {
  content: "\f219";
}

.flaticon-share-1:before {
  content: "\f21a";
}

.flaticon-share-2:before {
  content: "\f21b";
}

.flaticon-shuffle:before {
  content: "\f21c";
}

.flaticon-shuffle-1:before {
  content: "\f21d";
}

.flaticon-shutdown:before {
  content: "\f21e";
}

.flaticon-sign:before {
  content: "\f21f";
}

.flaticon-sign-1:before {
  content: "\f220";
}

.flaticon-skip:before {
  content: "\f221";
}

.flaticon-smartphone:before {
  content: "\f222";
}

.flaticon-smartphone-1:before {
  content: "\f223";
}

.flaticon-smartphone-10:before {
  content: "\f224";
}

.flaticon-smartphone-11:before {
  content: "\f225";
}

.flaticon-smartphone-2:before {
  content: "\f226";
}

.flaticon-smartphone-3:before {
  content: "\f227";
}

.flaticon-smartphone-4:before {
  content: "\f228";
}

.flaticon-smartphone-5:before {
  content: "\f229";
}

.flaticon-smartphone-6:before {
  content: "\f22a";
}

.flaticon-smartphone-7:before {
  content: "\f22b";
}

.flaticon-smartphone-8:before {
  content: "\f22c";
}

.flaticon-smartphone-9:before {
  content: "\f22d";
}

.flaticon-speaker:before {
  content: "\f22e";
}

.flaticon-speaker-1:before {
  content: "\f22f";
}

.flaticon-speaker-2:before {
  content: "\f230";
}

.flaticon-speaker-3:before {
  content: "\f231";
}

.flaticon-speaker-4:before {
  content: "\f232";
}

.flaticon-speaker-5:before {
  content: "\f233";
}

.flaticon-speaker-6:before {
  content: "\f234";
}

.flaticon-speaker-7:before {
  content: "\f235";
}

.flaticon-speaker-8:before {
  content: "\f236";
}

.flaticon-spotlight:before {
  content: "\f237";
}

.flaticon-star:before {
  content: "\f238";
}

.flaticon-star-1:before {
  content: "\f239";
}

.flaticon-stop:before {
  content: "\f23a";
}

.flaticon-stop-1:before {
  content: "\f23b";
}

.flaticon-stopwatch:before {
  content: "\f23c";
}

.flaticon-stopwatch-1:before {
  content: "\f23d";
}

.flaticon-stopwatch-2:before {
  content: "\f23e";
}

.flaticon-stopwatch-3:before {
  content: "\f23f";
}

.flaticon-stopwatch-4:before {
  content: "\f240";
}

.flaticon-street:before {
  content: "\f241";
}

.flaticon-street-1:before {
  content: "\f242";
}

.flaticon-substract:before {
  content: "\f243";
}

.flaticon-substract-1:before {
  content: "\f244";
}

.flaticon-success:before {
  content: "\f245";
}

.flaticon-switch:before {
  content: "\f246";
}

.flaticon-switch-1:before {
  content: "\f247";
}

.flaticon-switch-2:before {
  content: "\f248";
}

.flaticon-switch-3:before {
  content: "\f249";
}

.flaticon-switch-4:before {
  content: "\f24a";
}

.flaticon-switch-5:before {
  content: "\f24b";
}

.flaticon-switch-6:before {
  content: "\f24c";
}

.flaticon-switch-7:before {
  content: "\f24d";
}

.flaticon-tabs:before {
  content: "\f24e";
}

.flaticon-tabs-1:before {
  content: "\f24f";
}

.flaticon-target:before {
  content: "\f250";
}

.flaticon-television:before {
  content: "\f251";
}

.flaticon-television-1:before {
  content: "\f252";
}

.flaticon-time:before {
  content: "\f253";
}

.flaticon-trash:before {
  content: "\f254";
}

.flaticon-umbrella:before {
  content: "\f255";
}

.flaticon-unlink:before {
  content: "\f256";
}

.flaticon-unlocked:before {
  content: "\f257";
}

.flaticon-unlocked-1:before {
  content: "\f258";
}

.flaticon-unlocked-2:before {
  content: "\f259";
}

.flaticon-upload:before {
  content: "\f25a";
}

.flaticon-user:before {
  content: "\f25b";
}

.flaticon-user-1:before {
  content: "\f25c";
}

.flaticon-user-2:before {
  content: "\f25d";
}

.flaticon-user-3:before {
  content: "\f25e";
}

.flaticon-user-4:before {
  content: "\f25f";
}

.flaticon-user-5:before {
  content: "\f260";
}

.flaticon-user-6:before {
  content: "\f261";
}

.flaticon-user-7:before {
  content: "\f262";
}

.flaticon-users:before {
  content: "\f263";
}

.flaticon-users-1:before {
  content: "\f264";
}

.flaticon-video-camera:before {
  content: "\f265";
}

.flaticon-video-camera-1:before {
  content: "\f266";
}

.flaticon-video-player:before {
  content: "\f267";
}

.flaticon-video-player-1:before {
  content: "\f268";
}

.flaticon-video-player-2:before {
  content: "\f269";
}

.flaticon-view:before {
  content: "\f26a";
}

.flaticon-view-1:before {
  content: "\f26b";
}

.flaticon-view-2:before {
  content: "\f26c";
}

.flaticon-volume-control:before {
  content: "\f26d";
}

.flaticon-volume-control-1:before {
  content: "\f26e";
}

.flaticon-warning:before {
  content: "\f26f";
}

.flaticon-wifi:before {
  content: "\f270";
}

.flaticon-wifi-1:before {
  content: "\f271";
}

.flaticon-windows:before {
  content: "\f272";
}

.flaticon-windows-1:before {
  content: "\f273";
}

.flaticon-windows-2:before {
  content: "\f274";
}

.flaticon-windows-3:before {
  content: "\f275";
}

.flaticon-windows-4:before {
  content: "\f276";
}

.flaticon-wireless-internet:before {
  content: "\f277";
}

.flaticon-worldwide:before {
  content: "\f278";
}

.flaticon-worldwide-1:before {
  content: "\f279";
}

.flaticon-zoom-in:before {
  content: "\f27a";
}

.flaticon-zoom-out:before {
  content: "\f27b";
}

/* ============================================================================= */
/* Vivus SVG animation integration. */
/* ============================================================================= */
svg.dt-animated-svg--ready * {
  fill-opacity: 0;
  transition: fill-opacity 1s;
}

svg.dt-animated-svg--finished * {
  fill-opacity: 1;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* ============================================================================= */
/* Minimal counter integration. */
/* ============================================================================= */
.minimal-counter {
  display: inline-block;
  font-size: 0;
  height: 78px;
  overflow: hidden;
  text-align: center;
  position: relative;
}

.minimal-counter .digit {
  display: inline-block;
  font-size: 78px;
}

.minimal-counter .sequence {
  width: 0.53em;
  line-height: 1;
  transform: translate3d(0, -90%, 0);
  transition: transform 1s cubic-bezier(0.86, 0, 0.07, 1), width 1s ease-in-out, opacity 1s ease-in-out;
}

.minimal-counter .digit:last-child .sequence {
  transition-delay: 100ms;
}

.minimal-counter .sequence.is-hidden {
  width: 1px;
  margin-left: -1px;
  opacity: 0;
}

/* ============================================================================= */
/* Base styles for common elements. */
/* ============================================================================= */
@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/NotoSans-Light.ttf) format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/NotoSans-Regular.ttf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/NotoSans-Medium.ttf) format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/Montserrat-SemiBold.otf) format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/NotoSans-Bold.ttf) format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/NotoSans-Black.ttf) format("opentype");
  font-weight: 800;
  font-style: normal;
}

/* ============================================================================= */
/* Base styles for common elements. */
/* ============================================================================= */
html {
  -webkit-font-smoothing: antialiased;
}

body {
  position: relative;
  overflow-x: hidden;
  letter-spacing: 0.01em;
  background: #222 url(/assets/images/noise.png) repeat;
  animation: animatebg 0.25s steps(2) infinite;
  width: 100%;
  height: 100%;
}

@keyframes animatebg {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 100% 100%;
  }
}

p {
  margin-bottom: 2rem;
}

p a {
  border-bottom: 2px solid #fff;
}

a {
  transition: all 0.25s;
  outline: none;
}

hr {
  border-color: #eee;
  display: inline-block;
  width: 100%;
}

button, input, optgroup, select, textarea {
  font-family: "Mukta", "Montserrat", Arial, "Helvetica Neue", sans-serif;
}

.btn {
  border: none;
  border-radius: 26px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 2px;
  font-size: 16px;
  text-transform: uppercase;
}

.btn.btn-primary {
  color: #fff !important;
  background: rgba(255, 255, 255, 0.2);
  transition: all 0.2s ease-in-out;
}

.btn.btn-primary:hover {
  transition: all 0.2s ease-in-out;
  background: rgba(255, 255, 255, 0.3);
}

.btn.btn-primary:focus {
  box-shadow: none;
}

#dt-wrapper {
  position: relative;
  z-index: 10;
}

.form-control {
  letter-spacing: 0.04em;
  padding: 0.8rem 0rem;
  line-height: 1.56;
  border: none;
  border-bottom: 2px solid #eee;
  color: #2a2a2a;
  font-size: 18px;
}

.form-control:focus {
  color: #2a2a2a;
}

@media (max-width: 999px) {
  .text-right {
    text-align: center !important;
  }
  .text-left {
    text-align: center !important;
  }
  [class*='col-'] {
    padding-bottom: 30px;
  }
  [class*='col-']:last-child {
    padding-bottom: 0;
  }
  .dt-marg30, .dt-marg50 {
    margin-top: 30px;
  }
}

hr {
  margin: 0;
}

.dt-section {
  position: relative;
  z-index: 11;
  padding-top: 150px;
  padding-bottom: 150px;
}

@media (max-width: 1719px) {
  .dt-section {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media (max-width: 999px) {
  .dt-section {
    padding-top: 80px;
    padding-bottom: 80px;
    text-align: center;
  }
}

.dt-section .container {
  z-index: 13;
}

.dt-section.pt0 {
  padding-top: 0px !important;
}

.dt-section.pt05 {
  padding-top: 60px !important;
}

.dt-section.pb0 {
  padding-bottom: 0px !important;
}

.dt-section.none {
  padding: 0 !important;
}

.dt-section.white {
  color: #fff;
}

.dt-section.white h1, .dt-section.white h2, .dt-section.white h3, .dt-section.white h4, .dt-section.white h5, .dt-section.white h6 {
  color: #fff;
}

.dt-section.bg-color-white {
  background: #fff;
  margin: auto;
}

@media (min-width: 1720px) {
  .dt-section.bg-color-white.contacts {
    max-width: 85%;
    min-width: 1460px;
  }
}

.dt-section.bg-color-white.inner {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (min-width: 1720px) {
  .dt-section.bg-color-white.inner {
    max-width: 85%;
    min-width: 1460px;
  }
}

@media (max-width: 1339px) {
  .dt-section.bg-color-white {
    width: 100%;
  }
  .dt-section.bg-color-white .dt-section-animated-side {
    display: none;
  }
}

.dt-section.bg-color-dark {
  background: rgba(51, 51, 51, 0.5);
  margin: auto;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (min-width: 1720px) {
  .dt-section.bg-color-dark {
    max-width: 85%;
    min-width: 1460px;
  }
}

.dt-section img {
  max-width: 100%;
}

.dt-section hr {
  border-top: 1px solid #404040;
  margin-top: 75px;
  margin-bottom: 0px;
  display: inline-block;
  width: 100%;
}

@media (max-width: 1719px) {
  .dt-section hr {
    margin-top: 60px;
    margin-bottom: 0px;
  }
}

.dt-section .white {
  color: #fff;
}

.dt-section .container-fluid.p0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.dt-title-block {
  width: 100%;
}

.dt-title-block.white {
  margin-bottom: 80px;
}

.dt-title-block.white h1 {
  color: #fff;
}

.dt-title-block.white h5 {
  color: #757575;
  margin-bottom: 0;
}

@media (max-width: 1339px) {
  .dt-title-block.white {
    margin-bottom: 50px;
  }
}

.dt-title-block h1 {
  font-size: 68px;
  line-height: 1.13;
  margin-bottom: 0;
}

@media (max-width: 1719px) {
  .dt-title-block h1 {
    font-size: 66px;
  }
}

@media (max-width: 1339px) {
  .dt-title-block h1 {
    font-size: 60px;
    line-height: 1.2;
  }
}

@media (max-width: 999px) {
  .dt-title-block h1 {
    font-size: 50px;
    line-height: 1.3;
  }
}

.dt-title-block h4 {
  color: #2a2a2a;
  font-size: 29px;
  line-height: 1.5;
  margin-bottom: 0;
  margin-top: -15px;
}

@media (max-width: 999px) {
  .dt-title-block h4 {
    margin-top: 0;
    font-size: 24px;
  }
}

.dt-title-block h5 {
  color: #757575;
  line-height: 1.5;
  letter-spacing: 1px;
  font-size: 17px;
  font-family: "Mukta", "Montserrat", Arial, "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 45px;
}

@media (max-width: 999px) {
  .dt-title-block h5 {
    margin-top: 0;
  }
}

.dt-title-block h5.no-space {
  margin-top: 0;
}

.dt-title-block hr {
  border-top: 1px solid #DEDEDE;
  border-bottom: 1px solid #F8F8F8;
  margin: 60px 0;
}

@media (max-width: 999px) {
  .dt-title-block hr {
    margin: 30px 0;
  }
}

.dt-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

/* ================================= custom styles for icons */
[class^="icon-"]:before, [class*=" icon-"]:before {
  margin: 0;
}

/* ============================================================================= */
/* Bootstrap style customizations. */
/* ============================================================================= */
/* ================================= grid rows */
.row {
  max-width: none !important;
}

/* ================================= buttons and other form controls */
.btn {
  cursor: pointer;
}

.btn-lg {
  font-size: 15px;
  font-weight: 400;
}

.btn-white {
  color: #333;
  background-color: #fff;
  border-color: #fff;
}

.form-control:-webkit-autofill {
  -webkit-transition: none;
  -webkit-box-shadow: 0 0 0 30px #fff inset;
}

/* ================================= carousels */
.carousel-indicators {
  margin-bottom: 2rem;
}

.carousel-indicators li {
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.25s;
}

.carousel-indicators li:hover, .carousel-indicators li.active {
  background-color: #F54730;
}

.carousel-fade .carousel-inner .carousel-item {
  transition-property: opacity;
}

.carousel-fade .carousel-inner .carousel-item,
.carousel-fade .carousel-inner .active.carousel-item-left,
.carousel-fade .carousel-inner .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-inner .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .carousel-inner .carousel-item-next,
.carousel-fade .carousel-inner .carousel-item-prev,
.carousel-fade .carousel-inner .active.carousel-item-left,
.carousel-fade .carousel-inner .active.carousel-item-right {
  left: 0;
  transform: translate3d(0, 0, 0);
}

/* ================================= progress bar */
.progress {
  background-color: #ddd;
  border-radius: 0;
}

.progress .progress-bar {
  background-color: #222;
  height: 5px;
}

/* ================================= input groups */
.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-radius: 0;
}

.input-group .font-size,
.input-group .input-group-addon,
.input-group .input-group-btn {
  z-index: 10;
}

/* ============================================================================= */
/* Primary site preloader. */
/* ============================================================================= */
#dt-preloader:after, #dt-preloader:before {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15001;
  background: #2a2a2a;
  transform-origin: top center;
  content: '';
}

#dt-preloader:before {
  content: 'loading...';
  font-weight: 400;
  font-size: 2rem;
  font-family: Arial, Geneva, Verdana, sans-serif;
  letter-spacing: 3px;
  -webkit-text-stroke: 2px #fff;
  text-stroke: 2px #fff;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#dt-preloader:after {
  z-index: 15000;
  background: #fff;
}

.dt-page-loaded #dt-preloader:after, .dt-page-loaded #dt-preloader:before {
  animation: dt-preloader-slideup 0.5s 0.5s forwards ease;
}

.dt-page-loaded #dt-preloader:after {
  animation-delay: 1s;
}

@keyframes dt-preloader-slideup {
  0% {
    transform: scaleY(1);
    -webkit-text-stroke-color: #fff;
    text-stroke-color: #fff;
  }
  80% {
    -webkit-text-stroke-color: transparent;
    text-stroke-color: transparent;
  }
  100% {
    transform: scaleY(0);
  }
}

/* ============================================================================= */
/* Scroll top button. */
/* ============================================================================= */
.dt-scroll-top {
  position: fixed;
  padding: 10px;
  text-align: center;
  right: 30px;
  bottom: 30px;
  color: #fff;
  transform: translate3d(0, 20px, 0);
  transition: all 0.55s ease;
  z-index: 1000;
  border-radius: 20px;
  background-color: #F54730;
  opacity: 0;
  line-height: 1;
  visibility: hidden;
}

.dt-scroll-top.scroll-top-visible {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

.dt-scroll-top > i {
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  display: inline-block;
  opacity: 1;
  font-size: 20px;
  vertical-align: middle;
}

.dt-scroll-top > i:before {
  margin: 0;
}

.dt-scroll-top > .anno-text {
  padding-left: 10px;
  padding-right: 5px;
  white-space: nowrap;
  display: none;
  font-size: 14px;
  text-transform: uppercase;
  vertical-align: middle;
}

.dt-scroll-top:hover {
  background: #222;
  color: #fff;
}

/* ============================================================================= */
/* Flipping button. */
/* ============================================================================= */
.dt-flip-btn {
  display: inline-block;
  position: relative;
  text-align: center;
  width: 175px;
  padding: 15px 17px;
  transition: border-radius 0.25s;
  background: #F54730;
  color: #fff !important;
  border-radius: 30px;
}

.dt-flip-btn > .back, .dt-flip-btn > .front {
  display: inline-block;
  transform: translate3d(0, 0, 0);
  transition: all 0.25s;
}

.dt-flip-btn > .front {
  transition-delay: 0.25s;
}

.dt-flip-btn > .back {
  opacity: 0;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(calc(-50% - 15px));
  text-align: center;
  font-size: 25px;
}

.dt-flip-btn > .back i:before {
  background-image: linear-gradient(135deg, #21D4FD 0%, #4cafe1 75%);
  color: transparent !important;
  -webkit-background-clip: text;
  background-clip: text;
}

.dt-flip-btn i {
  margin-left: 5px;
}

.dt-flip-btn:hover {
  cursor: pointer;
}

.dt-flip-btn:hover > .front {
  opacity: 0;
  transform: translateY(15px);
  transition-delay: 0s;
}

.dt-flip-btn:hover > .back {
  opacity: 1;
  transform: translateY(-50%);
  transition-delay: 0.25s;
  background: #fff;
  border-radius: 30px;
}

/* ============================================================================= */
/* Site header commons. */
/* ============================================================================= */
#dt-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1050;
  background: transparent;
  padding: 30px 0;
  color: #fff;
  border-bottom: 1px solid transparent;
}

@media (max-width: 999px) {
  #dt-header {
    margin: 0;
    padding: 15px 0;
  }
}

#dt-header-inner {
  display: flex;
  align-items: center;
}

/* ============================================================================= */
/* Header elements (logo, extras, etc) */
/* ============================================================================= */
#dt-header-inner > .brand-logo {
  user-select: none;
  color: inherit;
  font-size: 2.1rem;
  margin-right: auto;
  min-width: 0;
  line-height: 1;
}

#dt-header-inner > .brand-logo > h1 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  color: inherit;
}

#dt-header-inner > .brand-logo > h1 > span {
  color: #F54730;
}

#dt-header-inner > .brand-logo img {
  max-width: 40%;
  height: auto;
}

#dt-header-inner > .brand-logo img.sticky {
  display: none;
}

#dt-header.headroom--pinned #dt-header-inner > .brand-logo .sticky {
  display: block;
}

#dt-header.headroom--pinned #dt-header-inner > .brand-logo .regular {
  display: none;
}

#dt-header.headroom--top #dt-header-inner > .brand-logo .regular {
  display: block;
}

#dt-header.headroom--top #dt-header-inner > .brand-logo .sticky {
  display: none;
}

/* ============================================================================= */
/* Header desktop navigation. */
/* ============================================================================= */
#dt-primary-nav {
  text-align: right;
  line-height: 1;
}

#dt-primary-nav > .nav_menu {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  margin: 0 -17px;
  font-size: 0;
}

#dt-primary-nav > .nav_menu::after {
  display: block;
  content: "";
  clear: both;
}

#dt-primary-nav > .nav_menu > li {
  position: relative;
  display: inline-block;
  margin: 15px 20px 15px 20px;
}

#dt-primary-nav > .nav_menu > li > a {
  display: inline-block;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 600;
  color: inherit;
  transition-property: opacity, color, background;
  text-transform: uppercase;
  position: relative;
}

#dt-primary-nav > .nav_menu > li > a:after {
  content: "";
  width: 0;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: auto;
  right: 0;
  bottom: -10px;
  transform: translateY(-50%);
  transition: all 0.35s ease-in;
}

#dt-primary-nav > .nav_menu > li > a:hover:after {
  width: 100%;
  right: auto;
  left: 0;
}

#dt-primary-nav > .nav_menu > li.menu-contacts button {
  display: inline-block;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 600;
  color: inherit;
  transition-property: opacity, color, background;
  text-transform: uppercase;
  position: relative;
  padding: 15px 25px 12px 25px;
  transition: all 0.2s ease-in-out;
  background: #fff;
  border-radius: 26px;
  color: #2a2a2a;
  border: none;
}

#dt-primary-nav > .nav_menu > li.menu-contacts button:hover {
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

#dt-primary-nav > .nav_menu > li.menu-contacts button:focus {
  outline: none;
}

#dt-primary-nav > .nav_menu .sub-menu {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  transform: translate3d(0, -10px, 0);
  transition: transform 0.35s, opacity 0.25s, visibility 0.35s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background: #fff;
  text-align: left;
}

#dt-primary-nav > .nav_menu .sub-menu.invert-attach-point {
  left: auto;
  right: 0;
}

#dt-primary-nav > .nav_menu .sub-menu > li {
  display: inline-block;
  position: relative;
}

#dt-primary-nav > .nav_menu .sub-menu > li > a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 15px;
  color: #333;
  width: 220px;
  line-height: 1.75;
  white-space: normal;
  transition-property: background;
  text-transform: capitalize;
  border-top: 1px solid #eee;
}

#dt-primary-nav > .nav_menu .sub-menu > li.menu-item-has-children > a:after {
  right: 20px;
  position: absolute;
  padding-left: 7px;
  content: '\e81f';
  font-size: 14px;
  font-family: "fontello";
}

#dt-primary-nav > .nav_menu .sub-menu > li:hover > a {
  background: #f5f5f5;
}

#dt-primary-nav > .nav_menu .sub-menu .sub-menu {
  transform: translate3d(-10px, 0, 0);
  left: 100%;
  top: 0;
}

#dt-primary-nav > .nav_menu .sub-menu .sub-menu.invert-attach-point {
  transform: translate3d(10px, 0, 0);
  right: 100%;
  left: auto;
}

#dt-primary-nav > .nav_menu > li.dt-mega-menu > ul {
  border-top: 1px solid #eee;
}

#dt-primary-nav > .nav_menu > li.dt-mega-menu > ul > li > a {
  width: 200px;
  border-top: 0;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

#dt-primary-nav > .nav_menu li.menu-item-has-children:hover > .sub-menu {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  visibility: visible;
}

/* ============================================================================= */
/* Fullscreen mobile navigation. */
/* ============================================================================= */
#dt-mobile-nav-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1030;
  background: rgba(0, 0, 0, 0.85);
  transition: opacity 0.3s, visibility 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  visibility: hidden;
}

/* ================================= fn menu toggle */
#dt-primary-nav #dt-mobile-nav-trigger {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  font-size: 18px;
  z-index: 1040;
  margin: 15px 0;
  width: 1.5em;
  height: 1em;
  color: inherit;
  transition: none;
}

#dt-primary-nav #dt-mobile-nav-trigger > span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: currentColor;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: all .3s ease-in-out, color 0.25s;
}

#dt-primary-nav #dt-mobile-nav-trigger > span:nth-child(1) {
  top: 0;
}

#dt-primary-nav #dt-mobile-nav-trigger > span:nth-child(2), #dt-primary-nav #dt-mobile-nav-trigger > span:nth-child(3) {
  top: 50%;
  margin-top: -1px;
}

#dt-primary-nav #dt-mobile-nav-trigger > span:nth-child(4) {
  bottom: 0;
}

.dt-active-burger-menu #dt-primary-nav #dt-mobile-nav-trigger > span:nth-child(1) {
  top: 50%;
  width: 0;
  left: 50%;
}

.dt-active-burger-menu #dt-primary-nav #dt-mobile-nav-trigger > span:nth-child(2) {
  transform: rotate(45deg);
}

.dt-active-burger-menu #dt-primary-nav #dt-mobile-nav-trigger > span:nth-child(3) {
  transform: rotate(-45deg);
}

.dt-active-burger-menu #dt-primary-nav #dt-mobile-nav-trigger > span:nth-child(4) {
  bottom: 50%;
  width: 0;
  left: 50%;
}

/* ================================= social icons */
#dt-mobile-nav-container .dt-soc-icons {
  margin-top: 15px;
  font-size: 0;
  opacity: 0;
}

#dt-mobile-nav-container .dt-soc-icons > a {
  display: inline-block;
  color: #F54730;
  padding: 0 15px;
  opacity: 1;
  font-size: 23px;
}

#dt-mobile-nav-container .dt-soc-icons > a:hover {
  color: #fff;
}

/* ================================= inner nav menu */
#dt-mobile-nav-container .nav_menu {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
}

#dt-mobile-nav-container .nav_menu li > a.sub-active {
  color: #F54730;
}

#dt-mobile-nav-container .nav_menu > li.menu-item-has-children > a:after {
  content: '\f3d0';
  font-family: "fontello";
  margin-left: 5px;
  font-size: 12px;
  position: absolute;
  top: 2px;
}

#dt-mobile-nav-container .nav_menu > li {
  position: relative;
  opacity: 0;
}

#dt-mobile-nav-container .nav_menu > li > a {
  font-size: 20px;
  line-height: 35px;
  text-transform: uppercase;
  padding-bottom: 15px;
  display: block;
  color: #fff;
  font-weight: 400;
}

#dt-mobile-nav-container .nav_menu > li > a:hover {
  color: #F54730;
}

#dt-mobile-nav-container .nav_menu .sub-menu {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  padding: 0 0 15px;
  display: none;
}

#dt-mobile-nav-container .nav_menu .sub-menu a {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
  color: #fff;
  text-transform: none;
  display: block;
}

#dt-mobile-nav-container .nav_menu .sub-menu a:hover {
  color: #F54730;
}

/* ================================= active state */
body.dt-active-burger-menu #dt-mobile-nav-container {
  opacity: 1;
  visibility: visible;
}

body.dt-active-burger-menu #dt-mobile-nav-container .nav_menu > li, body.dt-active-burger-menu #dt-mobile-nav-container .dt-soc-icons {
  animation: dt-menu-effect 0.4s linear forwards;
}

body.dt-active-burger-menu #dt-header.header-stuck .brand-logo .logo-dark {
  display: none;
}

body.dt-active-burger-menu #dt-header.header-stuck .brand-logo .logo-white {
  display: block;
}

@keyframes dt-menu-effect {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

/* ============================================================================= */
/* Header stuck state. */
/* ============================================================================= */
#dt-header {
  position: fixed;
  transition: all 0.25s linear;
}

#dt-header a {
  transition-duration: 0.25s;
}

#dt-header:not(.header-stuck) .stuck-visible {
  display: none;
}

#dt-header.headroom {
  will-change: transform, background, color, box-shadow;
}

body:not(.dt-active-burger-menu) #dt-header.headroom--pinned.headroom--not-top {
  transform: translateY(0%);
  background: #fff;
  border-bottom-color: #eee;
  color: #333;
  box-shadow: 0 3px 20px 5px rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
}

body:not(.dt-active-burger-menu) #dt-header.headroom--pinned.headroom--not-top .brand-logo {
  font-size: 2.2rem;
}

body:not(.dt-active-burger-menu) #dt-header.headroom--pinned.headroom--not-top .brand-logo .logo-dark {
  display: block;
}

body:not(.dt-active-burger-menu) #dt-header.headroom--pinned.headroom--not-top .brand-logo .logo-white {
  display: none;
}

body:not(.dt-active-burger-menu) #dt-header.headroom--pinned.headroom--not-top #dt-primary-nav > .nav_menu > li.menu-contacts {
  margin: 10px 20px;
}

body:not(.dt-active-burger-menu) #dt-header.headroom--pinned.headroom--not-top #dt-primary-nav > .nav_menu > li.menu-contacts button {
  background: #2a2a2a;
  color: #fff;
}

#dt-header.headroom--unpinned, #dt-header.headroom--not-top.headroom--not-bottom {
  transform: translateY(-100%);
}

/* ============================================================================= */
/* Header responsiveness. */
/* ============================================================================= */
@media (max-width: 1339px) {
  #dt-primary-nav > .nav_menu {
    display: none;
  }
}

@media (min-width: 1340px) {
  #dt-primary-nav > #dt-mobile-nav-trigger {
    display: none;
  }
}

/* ============================================================================= */
/* Site footer styles. */
/* ============================================================================= */
.dt-footer {
  padding-top: 40px;
  padding-bottom: 40px;
}

.dt-footer p {
  margin-bottom: 0;
  color: #fff;
}

.dt-footer .dt-social-footer ul {
  padding: 0;
  margin: 0;
  display: inline-block;
}

.dt-footer .dt-social-footer ul li {
  list-style: none;
  display: inline-block;
  margin: 0px 5px;
}

.dt-footer .dt-social-footer ul li i {
  font-size: 19px;
  position: relative;
  top: 1px;
}

.dt-footer .dt-social-footer ul li a {
  color: #fff;
  border-bottom: 2px solid #fff;
}

.dt-footer .dt-links-footer ul {
  padding: 0;
  margin: 0;
}

.dt-footer .dt-links-footer ul li {
  list-style: none;
  display: inline-block;
  margin-left: 10px;
  position: relative;
}

.dt-footer .dt-links-footer ul li a:after {
  content: "";
  width: 0;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: auto;
  right: 0;
  bottom: -10px;
  transform: translateY(-50%);
  transition: all 0.35s ease-in;
}

.dt-footer .dt-links-footer ul li a:hover:after {
  width: 100%;
  right: auto;
  left: 0;
}

/* ============================================================================= */
/* Page intro block. */
/* ============================================================================= */
::-webkit-scrollbar {
  width: 0px;
}

#dt-intro-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.dt-intro {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-shadow: none;
  user-select: none;
  color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dt-intro .intro-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 10;
}

.dt-intro .intro-bg-dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.dt-intro .intro-bg-dots > .intro-bg-single-dot {
  position: relative;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dt-intro .intro-bg-dots > .intro-bg-single-dot > .dot-inner {
  width: 3px;
  height: 3px;
  background: #fff;
  opacity: 0.1;
  border-radius: 50%;
  will-change: opacity, transform;
}

.dt-intro .intro-body {
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -100px;
}

@media (max-width: 1719px) {
  .dt-intro .intro-body {
    margin-top: -50px;
  }
}

@media (max-width: 1339px) {
  .dt-intro .intro-body {
    margin-top: 0px;
  }
}

@media (max-width: 999px) {
  .dt-intro .intro-body {
    text-align: center;
  }
}

@media (max-width: 575px) {
  .dt-intro .intro-body {
    margin-top: 15px;
  }
}

.dt-intro .intro-body i {
  border-bottom: 3px solid #fff;
  font-style: normal;
}

@media (max-width: 1339px) {
  .dt-intro .intro-body i {
    border-bottom: 2px solid #fff;
  }
}

.dt-intro .intro-body h1 {
  margin-bottom: 3rem;
  font-size: 80px;
  color: #fff;
  line-height: 1.4;
}

.dt-intro .intro-body h1 .intro-cursor-hide {
  display: none;
}

.dt-intro .intro-body h1 .intro-cursor-show {
  display: block;
}

@media (max-width: 1719px) {
  .dt-intro .intro-body h1 {
    font-size: 75px;
    margin-bottom: 2.5rem;
  }
}

@media (max-width: 1339px) {
  .dt-intro .intro-body h1 {
    font-size: 68px;
    margin-bottom: 2.5rem;
    line-height: 1.5;
  }
}

@media (max-width: 999px) {
  .dt-intro .intro-body h1 {
    font-size: 60px;
    margin-bottom: 2.5rem;
    line-height: 1.7;
  }
}

@media (max-width: 767px) {
  .dt-intro .intro-body h1 {
    font-size: 58px;
    margin-bottom: 2.5rem;
    line-height: 1.5;
    margin-bottom: 2rem;
  }
}

@media (max-width: 575px) {
  .dt-intro .intro-body h1 {
    font-size: 46px;
    margin-bottom: 2.5rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  .dt-intro .intro-body h1 .intro-cursor-hide {
    display: block;
  }
  .dt-intro .intro-body h1 .intro-cursor-show {
    display: none;
  }
}

.dt-intro .intro-body h4 {
  font-weight: 400;
  font-size: 26px;
  color: #d0d0d0;
}

@media (max-width: 1719px) {
  .dt-intro .intro-body h4 {
    font-size: 25px;
  }
}

@media (max-width: 999px) {
  .dt-intro .intro-body h4 {
    font-size: 23px;
  }
}

@media (max-width: 767px) {
  .dt-intro .intro-body h4 {
    line-height: 1.5;
  }
}

@media (max-width: 575px) {
  .dt-intro .intro-body h4 {
    margin-bottom: 0.3rem;
  }
}

.dt-intro .intro-body .btn {
  margin-top: 58px;
  padding: 14px 40px 12px 40px;
}

@media (max-width: 1719px) {
  .dt-intro .intro-body .btn {
    margin-top: 48px;
  }
}

@media (max-width: 767px) {
  .dt-intro .intro-body .btn {
    margin-top: 30px;
  }
}

.dt-intro .intro-body canvas {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
}

/* ============================================================================= */
/* Services block. */
/* ============================================================================= */
.dt-services-container {
  margin: 0 -20px 0px;
  width: auto;
  max-width: none;
}

.dt-services-container::after {
  display: block;
  content: "";
  clear: both;
}

@media (max-width: 1339px) {
  .dt-services-container {
    margin: 0 -15px 0px;
  }
}

.dt-services-container > .dt-services-wrap {
  float: left;
  width: 33.33333%;
  padding: 0 20px 0px;
}

@media (max-width: 1339px) {
  .dt-services-container > .dt-services-wrap {
    padding: 0 15px 0px;
  }
}

.dt-services-container > .dt-services-wrap span {
  display: inline-block;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #757575;
  padding-left: 60px;
  margin-bottom: 15px;
}

@media (max-width: 1339px) {
  .dt-services-container > .dt-services-wrap span {
    padding-left: 40px;
  }
}

@media (max-width: 999px) {
  .dt-services-container > .dt-services-wrap span {
    display: none;
  }
}

@media (max-width: 1719px) {
  .dt-services-container > .dt-services-wrap {
    width: 33.333333%;
  }
}

@media (max-width: 999px) {
  .dt-services-container > .dt-services-wrap {
    width: 50%;
    margin-bottom: 0px;
  }
  .dt-services-container > .dt-services-wrap:last-child {
    margin-bottom: 0px;
  }
}

@media (max-width: 767px) {
  .dt-services-container > .dt-services-wrap {
    width: 100%;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .dt-services-container {
    margin-bottom: -30px;
  }
  .dt-services-container > .dt-services-wrap {
    width: 100%;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) and (max-width: 999px) {
  .dt-services-container > .dt-services-wrap:last-child {
    padding-bottom: 0;
  }
}

.dt-services-block {
  background: rgba(51, 51, 51, 0.5);
  padding: 80px 60px;
  position: relative;
  margin-bottom: 60px;
}

@media (max-width: 1719px) {
  .dt-services-block {
    padding: 60px;
  }
}

@media (max-width: 1339px) {
  .dt-services-block {
    padding: 40px;
  }
}

@media (max-width: 999px) {
  .dt-services-block {
    margin-bottom: 30px;
  }
}

.dt-services-block .icon {
  display: block;
  margin-bottom: 1rem;
  line-height: 1;
  font-size: 56px;
  position: relative;
  z-index: 2;
}

.dt-services-block .title {
  display: block;
  line-height: 1;
}

.dt-services-block .title h3 {
  font-size: 25px;
  line-height: 1.5;
  margin-bottom: 0;
  color: #fff;
}

@media (max-width: 1339px) {
  .dt-services-block .title h3 {
    font-size: 24px;
  }
}

.dt-services-block p {
  margin-top: 15px;
  font-size: 17px;
  line-height: 1.56;
  color: #fff;
  margin-bottom: 0;
  height: 130px;
}

@media (max-width: 999px) {
  .dt-services-block p {
    height: 182px;
  }
}

@media (max-width: 767px) {
  .dt-services-block p {
    height: auto;
  }
}

.dt-services-block .list {
  margin-top: 10px;
  display: none;
}

.dt-services-block .list ul {
  padding: 0;
  margin: 0;
}

.dt-services-block .list ul li {
  position: relative;
  list-style: none;
  padding: 5px 0;
  color: #fff;
  line-height: 1.56;
  font-size: 19px;
  letter-spacing: normal;
  padding-left: 15px;
}

@media (max-width: 1339px) {
  .dt-services-block .list ul li {
    font-size: 17px;
  }
}

.dt-services-block .list ul li:last-child {
  padding-bottom: 0;
}

.dt-services-block .list ul li:before {
  width: 6px;
  height: 6px;
  content: '';
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 17px;
}

@media (max-width: 999px) {
  .dt-services-block .list ul li:before {
    display: none;
  }
}

.dt-services-block p:last-child {
  margin-bottom: 0;
}

/* Animated Circle */
.dt-circle {
  position: relative;
  display: inline-block;
  margin: 0 15px;
  vertical-align: top;
  text-align: center;
  width: 125px;
  padding-top: 42px;
  height: 125px;
}

.dt-circle canvas {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.dt-circle span {
  font-weight: 700;
  font-size: 17px;
}

.dt-circle span:after {
  content: '%';
  margin-left: 0.1em;
}

@media (max-width: 575px) {
  .dt-circle {
    margin-top: 15px;
    margin-bottom: 0;
  }
}

@media (max-width: 999px) {
  .dt-circle {
    margin-bottom: 15px;
  }
}

.dt-circle-text {
  margin-top: -5px;
  font-size: 14px;
}

@media (max-width: 999px) {
  #dt-magic .text-right {
    text-align: center !important;
  }
}

/* ============================================================================= */
/* Theme progress bars. */
/* ============================================================================= */
.dt-progress-bar {
  margin: 1rem 0 2rem 0;
}

.dt-progress-bar .title {
  display: inline-block;
  width: 100%;
  margin-bottom: 0.5rem;
}

.dt-progress-bar .title span {
  float: right;
  font-size: 15px;
  position: relative;
  top: 2px;
}

.dt-progress-bar .progress-bar {
  transition: none;
}

/* ============================================================================= */
/* Mailchimp subscribe block. */
/* ============================================================================= */
.dt-subscribe-form {
  position: relative;
  max-width: 580px;
  padding: 0 20px;
  margin: 0 auto;
}

.dt-subscribe-form > .form-inner-wrap {
  position: relative;
  z-index: 50;
  transition: opacity 0.25s, transform 0.25s;
}

.dt-subscribe-form > .form-inner-wrap img {
  max-width: 130px;
  opacity: 0.2;
  margin-top: 2rem;
}

.dt-subscribe-form > .form-inner-wrap p.appear-message {
  margin-top: 1rem;
  margin-bottom: 0;
}

.dt-subscribe-form > .form-inner-wrap .input-group {
  width: 350px;
  margin: auto;
}

.dt-subscribe-form > .form-output {
  position: absolute;
  top: 50%;
  left: 0;
  padding: 0 20px;
  width: 100%;
  min-height: 118px;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transform: translateY(-50%) scale(0.8);
  background: inherit;
  transition: all 0.25s;
  text-align: center;
}

.dt-subscribe-form > .form-output:before {
  font-family: "fontello";
  font-size: 70px;
  line-height: 70px;
  display: block;
  margin: 0 auto 20px;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.dt-subscribe-form.mc-loading > .form-inner-wrap, .dt-subscribe-form.mc-invalid > .form-inner-wrap, .dt-subscribe-form.mc-valid > .form-inner-wrap {
  opacity: 0;
  transform: scale(0.8);
}

.dt-subscribe-form.mc-loading > .form-output, .dt-subscribe-form.mc-invalid > .form-output, .dt-subscribe-form.mc-valid > .form-output {
  visibility: visible;
  opacity: 1;
  transform: translateY(-50%) scale(1);
}

.dt-subscribe-form.mc-loading > .form-output:before, .dt-subscribe-form.mc-invalid > .form-output:before, .dt-subscribe-form.mc-valid > .form-output:before {
  animation-name: dt-mc-form-output-icon;
}

.dt-subscribe-form > .form-output.valid:before {
  content: '\f3fd';
  color: #5cb85c;
}

.dt-subscribe-form > .form-output.error:before {
  content: '\f404';
  color: #d9534f;
}

.dt-subscribe-form.mc-loading > .form-output:before {
  content: '\f45a';
  color: inherit;
  font-size: 55px;
  animation: dt-mc-loading-icon 1s linear infinite;
}

@keyframes dt-mc-loading-icon {
  from {
    transform: rotate(-360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes dt-mc-form-output-icon {
  from {
    transform: rotate(-360deg) scale(0.7);
    opacity: 0.3;
  }
  to {
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
}

/* ============================================================================= */
/* Short features list. */
/* ============================================================================= */
.dt-short-features-list {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  columns: 3;
  column-gap: 40px;
  margin-bottom: -0.5rem;
}

.dt-short-features-list > li {
  padding-left: 28px;
  position: relative;
  display: block;
  margin: 0 auto 0.5rem;
}

.dt-short-features-list > li:before {
  position: absolute;
  top: 0;
  left: 0;
  font-family: "fontello";
  content: '\e804';
  color: #F54730;
  display: inline-block;
}

@media (max-width: 999px) {
  .dt-short-features-list {
    column-count: 2;
  }
}

@media (max-width: 767px) {
  .dt-short-features-list {
    column-count: 1;
  }
  .dt-short-features-list > li {
    padding-left: 0;
  }
  .dt-short-features-list > li:before {
    position: static;
    margin-right: 7px;
  }
}

/* ============================================================================= */
/* Rolling numbers (fun fact). */
/* ============================================================================= */
.dt-fun-fact-container {
  display: flex;
  flex-wrap: wrap;
}

.dt-fun-fact-container > .dt-fun-fact {
  flex: 1 0 250px;
  min-width: 0;
}

@media (max-width: 1339px) {
  .dt-fun-fact-container > .dt-fun-fact {
    flex: 1 0 160px;
  }
}

@media (max-width: 999px) {
  .dt-fun-fact-container > .dt-fun-fact {
    flex: 1 0 150px;
  }
}

@media (max-width: 575px) {
  .dt-fun-fact-container > .dt-fun-fact {
    flex: 1 0 150px;
    margin-bottom: 30px;
  }
  .dt-fun-fact-container > .dt-fun-fact:last-child {
    margin-bottom: 0;
  }
}

.dt-fun-fact__number {
  color: #2a2a2a;
  font-family: Barlow, sans-serif;
  font-size: 78px;
  margin-bottom: 0;
  font-weight: 600;
  letter-spacing: normal;
}

.dt-fun-fact__title {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: normal;
  color: #757575;
}

.dt-fun-fact--color-light .dt-fun-fact__number {
  color: #fff;
}

.dt-fun-fact--color-light .dt-fun-fact__title {
  color: #fff;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  visibility: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1060;
  display: none;
  overflow: hidden;
  outline: 0;
  background: #000;
  justify-content: center;
  align-items: center;
}

.modal-open .modal {
  visibility: visible;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.9);
}

.modal-dialog {
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  pointer-events: none;
  background-color: #fff;
  border-radius: 10px;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -5%);
}

.modal.show .modal-dialog {
  transform: translate(0, 0);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  padding: 32px 50px;
}

.modal-content .close {
  padding: 0;
  margin: 0;
  position: absolute;
  right: 20px;
  top: 10px;
}

.modal-content .close:focus {
  outline: none;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}

.modal-header h4 {
  font-size: 21px;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.42;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
}

.modal-body .send-us-mes.audit {
  margin-top: 0px;
}

.modal-body .send-us-mes.audit .dt-contact-request {
  margin-top: 2rem;
}

.modal-body .send-us-mes.audit .dt-contact-request .btn {
  padding: 10px 20px;
  font-size: 14px;
}

.modal-body .send-us-mes.audit .returnmessage p {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  animation: fadeInFromNone 0.75s ease-out;
  transform: translate(0, 15px);
  margin-bottom: 0;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    width: 400px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 1000px) {
  .modal-lg {
    max-width: 800px;
  }
}

.dt-portfolio-wrap .dt-portfolio-block-data {
  visibility: hidden;
  position: absolute;
  left: -9999px;
}

.dt-portfolio-wrap .dt-portfolio-block {
  position: relative;
  overflow: hidden;
  height: 450px;
}

@media (min-width: 1920px) {
  .dt-portfolio-wrap .dt-portfolio-block {
    height: 500px;
  }
}

@media (max-width: 1719px) {
  .dt-portfolio-wrap .dt-portfolio-block {
    height: 400px;
  }
}

@media (max-width: 575px) {
  .dt-portfolio-wrap .dt-portfolio-block {
    height: 300px;
  }
}

.dt-portfolio-wrap .dt-portfolio-block:hover {
  cursor: grab;
}

.dt-portfolio-wrap .dt-portfolio-block.andaman .dt-portfolio-screen {
  background-image: url("../images/andaman.jpg");
}

.dt-portfolio-wrap .dt-portfolio-block.soprano .dt-portfolio-screen {
  background-image: url("../images/soprano.jpg");
}

.dt-portfolio-wrap .dt-portfolio-block.unibody .dt-portfolio-screen {
  background-image: url("../images/unibody.jpg");
}

.dt-portfolio-wrap .dt-portfolio-block.universal .dt-portfolio-screen {
  background-image: url("../images/universal.jpg");
}

.dt-portfolio-wrap .dt-portfolio-block.insomnia .dt-portfolio-screen {
  background-image: url("../images/insomnia.jpg");
}

.dt-portfolio-wrap .dt-portfolio-block.pioneer .dt-portfolio-screen {
  background-image: url("../images/pioneer.jpg");
}

.dt-portfolio-wrap .dt-portfolio-block.avenda .dt-portfolio-screen {
  background-image: url("../images/avenda.jpg");
}

.dt-portfolio-wrap .dt-portfolio-block.pheromone .dt-portfolio-screen {
  background-image: url("../images/pheromone.jpg");
}

.dt-portfolio-wrap .dt-portfolio-block:after {
  content: '';
  background: rgba(0, 0, 0, 0.75);
  transition: all 0.6s;
  z-index: 20;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.dt-portfolio-wrap .dt-portfolio-block:before {
  content: '';
  border: 10px solid rgba(51, 51, 51, 0.8);
  transition: all 0.6s;
  z-index: 21;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

.dt-portfolio-wrap .dt-portfolio-block a {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 25;
}

.dt-portfolio-wrap .dt-portfolio-block .dt-portfolio-screen {
  will-change: transform;
  position: absolute;
  z-index: 18;
  top: -20px;
  left: -20px;
  bottom: -20px;
  right: -20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  filter: grayscale(100%);
  transition: all 0.6s;
}

.dt-portfolio-wrap .owl-item.active.center .dt-portfolio-block:after {
  background: none;
  transition: all 0.6s;
}

.dt-portfolio-wrap .owl-item.active.center .dt-portfolio-block:before {
  border: 10px solid rgba(255, 255, 255, 0);
  transition: all 0.6s;
}

.dt-portfolio-wrap .owl-item.active.center .dt-portfolio-screen {
  filter: none;
  top: -50px;
  left: -50px;
  bottom: -50px;
  right: -50px;
  transition: all 0.6s;
}

@media (max-width: 575px) {
  .dt-portfolio-wrap .owl-item.active .dt-portfolio-block:after {
    background: none;
    transition: all 0.6s;
  }
  .dt-portfolio-wrap .owl-item.active .dt-portfolio-block:before {
    border: none;
    transition: all 0.6s;
  }
  .dt-portfolio-wrap .owl-item.active .dt-portfolio-screen {
    filter: none;
  }
}

.dt-portfolio-hash {
  margin-top: 30px;
}

.dt-portfolio-hash span {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #757575;
}

.dt-portfolio-hash h2 {
  padding-top: 7px;
  color: #fff;
  font-size: 30px;
  margin-bottom: 20px;
}

.dt-portfolio-hash p {
  font-size: 13px;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #d0d0d0;
  text-transform: uppercase;
  margin-bottom: 0;
}

/* Contacts */
.dt-contacts-list ul {
  padding: 0;
  margin: 0;
}

.dt-contacts-list ul li {
  list-style: none;
  margin-bottom: 0.45rem;
}

.dt-contacts-list ul li i {
  font-size: 30px;
  padding-right: 8px;
  position: relative;
  top: 5px;
}

.dt-contact-caption {
  font-size: 18px;
  margin-bottom: 18px;
  color: #2a2a2a;
  line-height: 1.56;
  font-weight: 600;
  margin-top: 60px;
}

@media (max-width: 999px) {
  .dt-contact-caption {
    text-align: center;
  }
}

.dt-contacts-info {
  color: #757575;
  line-height: 1.56;
  font-size: 18px;
}

.dt-contacts-info p {
  margin-bottom: 10px;
}

.dt-contacts-info a {
  color: #2a2a2a;
  line-height: 1.5;
  margin-left: -19px;
  font-size: 22px;
  display: inline-block;
  margin-bottom: 15px;
}

.dt-contacts-info i {
  left: -30px;
  position: relative;
  font-size: 24px;
  top: 2px;
}

.dt-contacts-info .dt-mail {
  margin-top: 15px;
}

.dt-contacts-info .dt-mail i {
  font-size: 22px;
  top: 0;
}

.dt-contacts-social {
  margin-top: 20px;
  display: inline-block;
}

.dt-contacts-social ul {
  padding: 0;
  margin: 0;
}

.dt-contacts-social ul li {
  list-style: none;
  display: inline-block;
  padding-right: 15px;
}

.dt-contacts-social ul li a {
  color: #333;
  background: #fff;
  padding: 5px 7px;
  line-height: 1;
  border-radius: 4px;
}

.dt-contacts-social ul li a i {
  font-size: 16px;
}

.send-us-mes {
  position: relative;
  margin-top: 60px;
  text-align: left !important;
}

.send-us-mes .form-group {
  position: relative;
  margin: 0 0 30px 0;
}

.send-us-mes .dt-contact-caption {
  margin-top: 0;
}

.send-us-mes .dt-contact-form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .send-us-mes .dt-contact-form {
    display: block;
  }
}

.send-us-mes .dt-contact-checkbox-wrap {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .send-us-mes .dt-contact-checkbox-wrap {
    margin-bottom: 15px;
    width: 50%;
    float: left;
  }
  .send-us-mes .dt-contact-checkbox-wrap.last {
    margin-bottom: 0;
  }
}

.send-us-mes .dt-contact-checkbox-wrap.last {
  margin-bottom: 0;
}

.send-us-mes .dt-contact-checkbox-wrap .dt-contacts-checkbox {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.send-us-mes .dt-contact-checkbox-wrap .dt-contacts-checkbox span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
  color: #757575;
  line-height: 1.56;
  font-size: 18px;
}

.send-us-mes .dt-contact-checkbox-wrap .dt-contacts-checkbox span:first-child {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #d8d8d8;
  transition: all .2s ease;
}

.send-us-mes .dt-contact-checkbox-wrap .dt-contacts-checkbox span:first-child svg {
  position: absolute;
  top: 4px;
  left: 3px;
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 20px;
  stroke-dashoffset: 20px;
  transition: all .3s ease;
  transition-delay: .1s;
  transform: translate3d(0, 0, 0);
}

.send-us-mes .dt-contact-checkbox-wrap .dt-contacts-checkbox span:first-child svg:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #2a2a2a;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}

.send-us-mes .dt-contact-checkbox-wrap .dt-contacts-checkbox span:last-child {
  padding-left: 8px;
}

.send-us-mes .dt-contact-checkbox-wrap .dt-contacts-checkbox:hover span:first-child {
  border-color: #2a2a2a;
}

.send-us-mes .dt-contact-checkbox-wrap .dt-contacts-checkbox-check:checked + .dt-contacts-checkbox span:first-child {
  background: #2a2a2a;
  border-color: #2a2a2a;
}

.send-us-mes .dt-contact-checkbox-wrap .dt-contacts-checkbox-check:checked + .dt-contacts-checkbox span:first-child svg {
  stroke-dashoffset: 0;
}

.send-us-mes ::placeholder {
  color: #999;
}

.send-us-mes .custom-select {
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #eee;
  color: #999;
  padding: 0;
  height: 51px;
}

.send-us-mes textarea {
  height: 40px;
  min-height: 38px;
  letter-spacing: 0.04em;
  line-height: 1.56;
  border: none;
  border-bottom: 2px solid #eee;
  color: #2a2a2a;
  font-size: 18px;
}

.send-us-mes .dt-attach-file {
  margin: 10px 30px 0 30px;
  display: inline-block;
}

@media (max-width: 999px) {
  .send-us-mes .dt-attach-file {
    width: 100%;
    text-align: center;
    margin: 0 0 -30px 0;
  }
}

.send-us-mes .dt-attach-file label {
  color: #2a2a2a;
  line-height: 1.5;
  border-bottom: 2px solid #2a2a2a;
  font-size: 18px;
  position: relative;
}

.send-us-mes .dt-attach-file label:before {
  position: absolute;
  content: '+';
  font-size: 30px;
  left: -30px;
  top: -6px;
}

.send-us-mes .dt-attach-file label:hover {
  cursor: pointer;
}

.send-us-mes .dt-attach-file .dt-file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.send-us-mes .dt-contact-request {
  margin-top: 30px;
}

@media (max-width: 999px) {
  .send-us-mes .dt-contact-request {
    text-align: center;
  }
}

.send-us-mes .dt-contact-request .btn {
  background: #2A2A2A;
  padding: 14px 33px;
}

.send-us-mes .returnmessage {
  position: relative;
  display: inline-block;
}

.send-us-mes .returnmessage p {
  margin-bottom: 0;
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 15px);
  }
}

.dt-contacts-effect {
  padding: 4px 0;
  border-bottom: 2px solid #eee;
  background-color: transparent;
}

.dt-contacts-effect ~ .focus-border {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #2a2a2a;
  transition: 0.3s;
}

.dt-contacts-effect:focus ~ .focus-border, .dt-contacts-effect.has-content ~ .focus-border {
  width: 100%;
  transition: 0.3s;
}

.dt-contacts-effect:focus {
  background: transparent;
}

.dt-contacts-effect:focus ~ label, .dt-contacts-effect.has-content ~ label {
  top: -16px;
  font-size: 14px;
  color: #2A2A2A;
  transition: 0.3s;
}

.dt-contacts-effect ~ label {
  position: absolute;
  left: 0;
  width: 100%;
  top: 7px;
  color: #aaa;
  transition: 0.3s;
  z-index: -1;
  letter-spacing: 1px;
  margin: 0;
}

.dt-testimonials-block .dt-testimonials {
  background: rgba(51, 51, 51, 0.5);
  padding: 60px;
}

@media (max-width: 1719px) {
  .dt-testimonials-block .dt-testimonials {
    padding: 44px;
  }
}

.dt-testimonials-block .dt-testimonials span {
  line-height: 1;
}

.dt-testimonials-block .dt-testimonials span i {
  font-size: 20px;
  opacity: 1;
  color: #fff;
}

.dt-testimonials-block .dt-testimonials p {
  position: relative;
  font-weight: 400;
  line-height: 1.25;
  font-size: 21px;
  color: #fff;
  margin-bottom: 40px;
  margin-left: 25px;
  text-align: left !important;
  min-height: 156px;
}

.dt-testimonials-block .dt-testimonials p:before {
  position: absolute;
  content: "“";
  font-family: Mukta;
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  top: 5px;
  left: -25px;
}

.dt-testimonials-block .dt-testimonials img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: inline-block;
  float: left;
  margin-right: 15px;
}

.dt-testimonials-block .dt-testimonials h6 {
  font-family: "Mukta", "Montserrat", Arial, "Helvetica Neue", sans-serif;
  font-size: 19px;
  line-height: 1.3;
  color: #fff;
  margin-bottom: 0;
  font-weight: 400;
  text-align: left !important;
}

.dt-testimonials-block .dt-testimonials h6 span {
  font-size: 16px;
  line-height: 1.5;
  color: #757575;
  font-weight: 400;
}

/* ============================================================================= */
/* About section. */
/* ============================================================================= */
#dt-about {
  margin-top: -100px;
  position: relative;
}

@media (max-width: 1719px) {
  #dt-about {
    margin-top: -75px;
  }
}

@media (max-width: 1339px) {
  #dt-about {
    margin-top: 0px;
  }
}

#dt-about .dt-section-animated-side {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #fff;
  width: 1px;
}

#dt-about .dt-section-animated-side-left {
  right: 100%;
  transform-origin: right center;
}

#dt-about .dt-section-animated-side-right {
  left: 100%;
  transform-origin: left center;
}

/* ============================================================================= */
/* pricing block. */
/* ============================================================================= */
.dt-pricing-container {
  margin: 0 -20px 0px;
  width: auto;
  max-width: none;
}

.dt-pricing-container::after {
  display: block;
  content: "";
  clear: both;
}

@media (max-width: 1339px) {
  .dt-pricing-container {
    margin: 0 -15px 0px;
  }
}

.dt-pricing-container > .dt-pricing-wrap {
  float: left;
  width: 33.33333%;
  padding: 0 20px 0px;
}

@media (max-width: 1339px) {
  .dt-pricing-container > .dt-pricing-wrap {
    padding: 0 15px 0px;
  }
}

@media (max-width: 1719px) {
  .dt-pricing-container > .dt-pricing-wrap {
    width: 33.333333%;
  }
}

@media (max-width: 999px) {
  .dt-pricing-container > .dt-pricing-wrap {
    width: 100%;
    margin-bottom: 30px;
  }
  .dt-pricing-container > .dt-pricing-wrap:last-child {
    margin-bottom: 0px;
  }
}

@media (max-width: 767px) {
  .dt-pricing-container > .dt-pricing-wrap {
    width: 100%;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .dt-pricing-container {
    margin-bottom: -30px;
  }
  .dt-pricing-container > .dt-pricing-wrap {
    width: 100%;
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) and (max-width: 999px) {
  .dt-pricing-container > .dt-pricing-wrap:last-child {
    padding-bottom: 0;
  }
}

.dt-pricing-block {
  background: #fff;
  position: relative;
  background: rgba(51, 51, 51, 0.5);
}

.dt-pricing-block:hover .list ul:after {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.dt-pricing-block .title {
  display: block;
  line-height: 1;
  padding: 30px 60px 30px 60px;
  background: #fff;
}

.dt-pricing-block .title h3 {
  font-size: 30px;
  line-height: 1.5;
  margin-bottom: 10px;
}

@media (max-width: 1339px) {
  .dt-pricing-block .title h3 {
    font-size: 24px;
  }
}

.dt-pricing-block .title p {
  color: #777;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 0;
}

.dt-pricing-block .price-wrap {
  padding: 30px 60px 30px 60px;
}

.dt-pricing-block .price-wrap .price {
  width: 100%;
}

.dt-pricing-block .price-wrap .price h1 {
  margin: 0;
  font-size: 52px;
  color: #fff;
  display: inline-block;
  margin-right: 15px;
}

@media (max-width: 767px) {
  .dt-pricing-block .price-wrap .price h1 {
    font-size: 40px;
    margin-right: 10px;
  }
}

.dt-pricing-block .price-wrap .price span {
  width: 2px;
  height: 43px;
  background: #555;
  display: inline-block;
  margin-right: 15px;
}

@media (max-width: 767px) {
  .dt-pricing-block .price-wrap .price span {
    height: 34px;
    margin-right: 10px;
  }
}

.dt-pricing-block .price-wrap .price p {
  display: inline-block;
  color: #fff;
  line-height: 1.3;
  font-size: 14px;
  margin-bottom: 1rem;
}

.dt-pricing-block .price-wrap .price p del {
  font-size: 26px;
  padding-right: 10px;
  display: block;
}

@media (max-width: 767px) {
  .dt-pricing-block .price-wrap .price p del {
    font-size: 22px;
  }
}

.dt-pricing-block .price-wrap a.btn.btn-white {
  margin-top: 10px;
  font-size: 17px;
  background: #fff;
  padding: 6px 18px 5px 18px;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
}

.dt-pricing-block .price-wrap a.btn.btn-white:hover {
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  color: inherit;
}

.dt-pricing-block .list {
  padding: 30px 60px;
  background: #fff;
}

.dt-pricing-block .list ul {
  position: relative;
  padding: 0;
  margin: 0;
  max-height: 240px;
  height: auto;
  transition: max-height 0.3s ease-in;
  overflow: hidden;
}

.dt-pricing-block .list ul.height {
  max-height: 749px;
  transition: max-height 0.3s ease-in;
}

.dt-pricing-block .list ul.height:after {
  content: '\e806';
  opacity: 0;
  cursor: default;
}

.dt-pricing-block .list ul:after {
  font-family: "fontello";
  content: '\e805';
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 18px;
  color: #000;
  border-top: 1px solid #eee;
  line-height: 35px;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
}

.dt-pricing-block .list ul li {
  position: relative;
  list-style: none;
  padding: 7px 0;
  line-height: 1.56;
  font-size: 17px;
  border-bottom: 1px solid #eee;
}

@media (max-width: 1339px) {
  .dt-pricing-block .list ul li {
    font-size: 17px;
  }
}

.dt-pricing-block .list ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.dt-pricing-block p:last-child {
  margin-bottom: 0;
}
