/* ============================================================================= */
/* Base styles for common elements. */
/* ============================================================================= */


@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/NotoSans-Light.ttf) format("opentype");
  font-weight: 300;
  font-style: normal
}

@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/NotoSans-Regular.ttf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/NotoSans-Medium.ttf) format("opentype");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/Montserrat-SemiBold.otf) format("opentype");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/NotoSans-Bold.ttf) format("opentype");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: 'NotoSans';
  src: url(/assets/fonts/NotoSans-Black.ttf) format("opentype");
  font-weight: 800;
  font-style: normal
}


/* ============================================================================= */
/* Base styles for common elements. */
/* ============================================================================= */

html { -webkit-font-smoothing : antialiased; }

body {
  position       : relative;
  overflow-x     : hidden;
  letter-spacing : 0.01em;
  background: #222 url(/assets/images/noise.png) repeat;
  animation: animatebg .25s steps(2) infinite;
  width: 100%;
  height: 100%;
}

@keyframes animatebg {
  0% {background-position: 0 0;}
  50% {background-position: 50% 50%;}
  100% {background-position: 100% 100%;}
}

p { margin-bottom : 2rem; 
  a {border-bottom: 2px solid #fff;}
}
a { transition : all 0.25s; outline: none; }
hr { border-color : #eee; display : inline-block; width : 100%; }
button, input, optgroup, select, textarea { font-family : $font-family-base }
.btn {
  border:none;
  border-radius: 26px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 2px;
  font-size: 16px;
  text-transform: uppercase;
  &.btn-primary {
    color: #fff !important;
    background: rgba(255,255,255,0.2);
    transition: all 0.2s ease-in-out;
    &:hover {
      transition: all 0.2s ease-in-out;
      background: rgba(255,255,255,0.3);
    }
    &:focus {
      box-shadow: none;
    }
  }
}
#dt-wrapper {
  position: relative;
  z-index: 10;
}
.form-control {
  letter-spacing: 0.04em;
  padding: 0.8rem 0rem;
  line-height: 1.56;
  border:none;
  border-bottom: 2px solid #eee;
  color:#2a2a2a;
  font-size: 18px;
  &:focus {color:#2a2a2a;}
}
@include media-breakpoint-down(md) {
  .text-right {text-align: center !important}
  .text-left {text-align: center !important}
  [class*='col-'] {
    padding-bottom: 30px;
    &:last-child {padding-bottom:0;}
  }
  .dt-marg30, .dt-marg50 {margin-top: 30px;}
}
hr {margin: 0;}
.dt-section {
    position: relative;
    z-index: 11;
    padding-top: 150px;
    padding-bottom: 150px;
    @include media-breakpoint-down(xl) {padding-top: 120px;padding-bottom: 120px;}
    @include media-breakpoint-down(md) {padding-top: 80px;padding-bottom: 80px; text-align: center;}
    .container {z-index: 13;}
    &.pt0 {padding-top: 0px !important;}
    &.pt05 {padding-top: 60px !important;}
    &.pb0 {padding-bottom: 0px !important;}
    &.none {padding: 0 !important;}
    &.white {
      color:#fff;
      h1,h2,h3,h4,h5,h6 {color:#fff;}
    }
    &.bg-color-white {
      background: #fff;
      margin: auto;
      &.contacts {
        @include media-breakpoint-up(xxl) {max-width: 85%;min-width: 1460px;}
      }
      &.inner {
        padding-top: 80px;
        padding-bottom: 80px;
        @include media-breakpoint-up(xxl) {max-width: 85%;min-width: 1460px;}
      }
      @include media-breakpoint-down(lg) {
        .dt-section-animated-side {display: none;}
        width:100%;
      }
    } 
    &.bg-color-dark {
      background: rgba(51,51,51,0.5);
      margin: auto;
      padding-top: 80px;
      padding-bottom: 80px;
      @include media-breakpoint-up(xxl) {max-width: 85%;min-width: 1460px;}
    } 
    img {max-width: 100%;}
    hr {
      border-top: 1px solid #404040;
      margin-top: 75px;
      margin-bottom: 0px;
      @include media-breakpoint-down(xl) {margin-top: 60px;margin-bottom: 0px;}
      display: inline-block;
      width: 100%;
    }
    .white {color:#fff;}
    .container-fluid {
      &.p0 {padding-left: 0px !important; padding-right: 0px !important;}
    }
  }

.dt-title-block {
  width: 100%;
  &.white {
    h1 {color:#fff;}
    h5 {color:#757575;margin-bottom: 0;}
    margin-bottom: 80px;
    @include media-breakpoint-down(lg) {margin-bottom: 50px;}
  }
  h1 {
    font-size: 68px;
    @include media-breakpoint-down(xl) {font-size: 66px;}
    @include media-breakpoint-down(lg) {font-size: 60px;line-height: 1.2;}
    @include media-breakpoint-down(md) {font-size: 50px;line-height: 1.3;}
    line-height: 1.13;
    margin-bottom: 0;
  }
  h4 {
    color:#2a2a2a;
    font-size: 29px;
    line-height: 1.5;
    margin-bottom: 0;
    margin-top: -15px;
    @include media-breakpoint-down(md) {margin-top:0;font-size:24px;}
  }
  h5 {
    color:#757575;
    line-height: 1.5;
    letter-spacing: 1px;
    font-size: 17px;
    font-family: $font-family-base;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 45px;
    @include media-breakpoint-down(md) {margin-top:0;}
    &.no-space {margin-top: 0;}
  }
  hr {
    border-top:1px solid #DEDEDE;
    border-bottom: 1px solid #F8F8F8;
    margin: 60px 0;
    @include media-breakpoint-down(md) {margin:30px 0;}
  }
}

.dt-overlay {
  @include overlay-base();
  //z-index: -1;
  background: rgba(0, 0, 0, 0.6);
}

/* ================================= custom styles for icons */
[class^="icon-"]:before, [class*=" icon-"]:before {
  margin : 0;
}