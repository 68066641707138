/* ============================================================================= */
/* Minimal counter integration. */
/* ============================================================================= */

.minimal-counter {
    display:    inline-block;
    font-size:  0;
    height:     78px;
    overflow:   hidden;
    text-align: center;
    position: relative;
  }
  
  .minimal-counter .digit {
    display:    inline-block;
    font-size:  78px;
  }
  
  .minimal-counter .sequence {
    width:       0.53em;
    line-height: 1;
    transform:   translate3d(0, -90%, 0);
    transition:  transform 1s cubic-bezier(0.860, 0.000, 0.070, 1.000), width 1s ease-in-out, opacity 1s ease-in-out;
  }
  
  .minimal-counter .digit:last-child .sequence {
    transition-delay: 100ms;
  }
  
  .minimal-counter .sequence.is-hidden {
    width:       1px;
    margin-left: -1px;
    opacity:     0;
  }