/* ============================================================================= */
/* Site footer styles. */
/* ============================================================================= */

.dt-footer {
  padding-top: 40px;
  padding-bottom: 40px;
  p {margin-bottom: 0; color:#fff;}
  .dt-social-footer {
    ul {
      padding: 0;
      margin: 0;
      display: inline-block;
      li {
        list-style: none;
        display: inline-block;
        margin: 0px 5px;
        i {font-size: 19px; position: relative; top:1px;}
        a {
          color:#fff;
          border-bottom: 2px solid #fff;
        }
      }
    }
  } 
  .dt-links-footer {
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        display: inline-block;
        margin-left: 10px;
        position: relative;
        a:after {
          content: "";
          width: 0;
          height: 2px;
          background-color: #fff;
          position: absolute;
          left: auto;
          right: 0;
          bottom: -10px;
          transform: translateY(-50%);
          transition: all 0.35s ease-in;
        }
        a:hover:after {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
    }
  }

}