/* ============================================================================= */
/* Page intro block. */
/* ============================================================================= */

::-webkit-scrollbar {
  width: 0px;
}

#dt-intro-wrapper {
  position: fixed;
  left:     0;
  top:      0;
  width:    100%;
}

.dt-intro {
  position:        relative;
  overflow:        hidden;
  width:           100%;
  text-shadow:     none;
  user-select:     none;
  color:           #fff;
  height:          100vh;
  display:         flex;
  flex-direction:  column;
  justify-content: center;

  .intro-bg {
    position: absolute;
    left:     0;
    right:    0;
    bottom:   0;
    top:      0;
    z-index:  10;
  }

  .intro-bg-dots {
    display:         flex;
    flex-wrap:       wrap;
    justify-content: space-around;

    > .intro-bg-single-dot {
      position:        relative;
      height:          50px;
      width:           50px;
      display:         flex;
      align-items:     center;
      justify-content: center;

      > .dot-inner {
        width:         3px;
        height:        3px;
        background:    #fff;
        opacity:       0.1;
        border-radius: 50%;
        will-change:   opacity, transform;
      }
    }
  }

  .intro-body {
    position:        relative;
    z-index:         20;
    display:         flex;
    flex-direction:  column;
    justify-content: center;
    margin-top: -100px;
    @include media-breakpoint-down(xl) {margin-top:-50px;}
    @include media-breakpoint-down(lg) {margin-top: 0px;}
    @include media-breakpoint-down(md) {text-align: center;}
    @include media-breakpoint-down(xs) {margin-top: 15px;}


    i {
      border-bottom: 3px solid #fff;
      @include media-breakpoint-down(lg) {border-bottom: 2px solid #fff;}
      font-style:    normal;
    }

    h1 {
      margin-bottom: 3rem;
      font-size:     80px;
      color:         #fff;
      line-height: 1.4;
      .intro-cursor-hide {display: none;}
      .intro-cursor-show {display: block;}
      @include media-breakpoint-down(xl) {font-size: 75px; margin-bottom: 2.5rem;}
      @include media-breakpoint-down(lg) {font-size: 68px; margin-bottom: 2.5rem;line-height: 1.5;}
      @include media-breakpoint-down(md) {font-size: 60px; margin-bottom: 2.5rem;line-height: 1.7;}
      @include media-breakpoint-down(sm) {font-size: 58px; margin-bottom: 2.5rem;line-height: 1.5;margin-bottom: 2rem;}
      @include media-breakpoint-down(xs) {
        font-size: 46px;
        margin-bottom: 2.5rem;
        line-height: 1.5;
        margin-bottom: 1rem;
        .intro-cursor-hide {display: block;}
        .intro-cursor-show {display: none;}
      }


    }

    h4 {
      font-weight: 400;
      font-size:   26px;
      color:       #d0d0d0;
      @include media-breakpoint-down(xl) {font-size: 25px;}
      @include media-breakpoint-down(md) {font-size: 23px;}
      @include media-breakpoint-down(sm) {line-height: 1.5}
      @include media-breakpoint-down(xs) {margin-bottom: 0.3rem;}

    }

    .btn {
      margin-top: 58px;
      padding:    14px 40px 12px 40px;
      @include media-breakpoint-down(xl) {margin-top:48px;}
      @include media-breakpoint-down(sm) {margin-top:30px;}
    }
      canvas{
      width: 100vw;
      height: 100vh;
      position: absolute;
      top:0;
    }
  }
}