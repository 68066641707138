/* ============================================================================= */
/* Header stuck state. */
/* ============================================================================= */
#dt-header {
  position:   fixed;
  transition: all 0.25s linear;

  a {
    transition-duration: 0.25s;
  }

  &:not(.header-stuck) .stuck-visible {
    display: none;
  }
}

#dt-header.headroom {
  will-change: transform, background, color, box-shadow;
}

body:not(.dt-active-burger-menu) #dt-header.headroom--pinned.headroom--not-top {
  transform:           translateY(0%);
  background:          #fff;
  border-bottom-color: #eee;
  color:               #333;
  box-shadow:          0 3px 20px 5px rgba(0, 0, 0, 0.1);
  padding-top:         10px;
  padding-bottom:      10px;

  .brand-logo {
    font-size: 2.2rem;

    .logo-dark {
      display: block;
    }

    .logo-white {
      display: none;
    }
  }

  #dt-primary-nav > .nav_menu > li.menu-contacts {
    margin: 10px 20px;
    button {
      background: #2a2a2a;
      color:#fff;
    }
  }
}

#dt-header.headroom--unpinned, #dt-header.headroom--not-top.headroom--not-bottom {
  transform: translateY(-100%);
}

/* ============================================================================= */
/* Header responsiveness. */
/* ============================================================================= */
@include media-breakpoint-down(lg) {
  #dt-primary-nav > .nav_menu {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  #dt-primary-nav > #dt-mobile-nav-trigger {
    display: none;
  }
}

