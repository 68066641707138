/* ============================================================================= */
/* Rolling numbers (fun fact). */
/* ============================================================================= */

.dt-fun-fact-container {
  display: flex;
  flex-wrap: wrap;

  > .dt-fun-fact {
    flex: 1 0 250px;
    @include media-breakpoint-down(lg) {flex: 1 0 160px;}
    @include media-breakpoint-down(md) {flex: 1 0 150px;}
    @include media-breakpoint-down(xs) {
      flex: 1 0 150px;
      margin-bottom: 30px;
      &:last-child {margin-bottom:0;};
    }
    min-width: 0;
  }
}

.dt-fun-fact {
  &__number {
    color:#2a2a2a;
    font-family: Barlow, sans-serif;
    font-size: 78px;
    margin-bottom: 0;
    font-weight: 600;
    letter-spacing: normal;
  }

  &__title {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: normal;
    color: #757575;
  }
}

.dt-fun-fact--color-light {
  .dt-fun-fact__number {
    color: #fff;
  }

  .dt-fun-fact__title {
    color: #fff;
  }
}