/* ============================================================================= */
/* Vivus SVG animation integration. */
/* ============================================================================= */

svg.dt-animated-svg--ready * {
    fill-opacity: 0;
    transition:   fill-opacity 1s;
}
  
svg.dt-animated-svg--finished * {
    fill-opacity: 1;
}